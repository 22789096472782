import {Stages} from "../stages/stages";
import {DataDispatcher} from "../test_runner/data_dispatcher";
import {CalculatedDataSource, LoadedDataSource} from "../test_runner/data_source";
import {AppContextObject} from "../stages/context.js";


export default class ModelLoader {
  stages = [
    Stages.UNLOADED,
    Stages.DOWNLINK,
    Stages.UPLINK,
  ];

  load_box_plot_scale(model) {
    DataDispatcher.broadcast_to(LoadedDataSource.BOX_PLOT_SCALE, model.box_plot_scale);
  }

  load_latencies(model) {
    const source_lookup = {
      [Stages.UNLOADED]: LoadedDataSource.LOADED_UNLOADED_LATENCIES,
      [Stages.DOWNLINK]: LoadedDataSource.LOADED_DOWNLINK_LATENCIES,
      [Stages.UPLINK]: LoadedDataSource.LOADED_UPLINK_LATENCIES,
    };
    Object.keys(model.test_data).forEach(stage => {
      DataDispatcher.broadcast_to(
        source_lookup[stage],
        model.test_data[stage].latencies.map(v => {return {value: v[0], timestamp: v[1]}})
      );
    });
  }

  load_bandwidths(model) {
    const source_lookup = {
      [Stages.DOWNLINK]: [LoadedDataSource.LOADED_DOWNLINK_SPEED, "downlink_bandwidth"],
      [Stages.UPLINK]: [LoadedDataSource.LOADED_UPLINK_SPEED, "uplink_bandwidth"],
    };
    for (const stage of Object.keys(source_lookup)) {
      const [channel, key_lookup] = source_lookup[stage];
      DataDispatcher.broadcast_to(
        channel,
        model[key_lookup]
      );
    }
  }

  load_test_report(model) {
    DataDispatcher.broadcast_to(CalculatedDataSource.REPORT, model.test_report);
  }

  load_model(model) {
    this.load_box_plot_scale(model);
    this.load_latencies(model);
    this.load_bandwidths(model);
    this.load_test_report(model);
    AppContextObject.loaded_test = model;
  }
}
