import React, {Component} from 'react';
import "./bufferbloat_grade_component.scss";
import {DataDispatcher} from "../test_runner/data_dispatcher";
import {CalculatedDataSource, UIEvents} from "../test_runner/data_source";


export default class BufferbloatGradeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bufferbloat_grade: null
    };
    this.text_lookup = {
      "A+": "Your latency did not increase under load.",
      "A": "Your latency increased slightly under load.",
      "B": "Your latency increased moderately under load.",
      "C": "Your latency increased considerably under load.",
      "D": "Your latency increased a lot under load.",
      "F": "Your latency increased severely under load."
    }
  }

  componentDidMount() {
    this.sub_id = DataDispatcher.subscribe_to(CalculatedDataSource.REPORT, (report) => {
      this.setState({...report});
    });
  }

  componentWillUnmount() {
    DataDispatcher.unsubscribe_from(CalculatedDataSource.REPORT, this.sub_id);
  }

  render() {
    const grade = this.state.bufferbloat_grade;
    if (!grade) {
      return null;
    }

    return (
      <div className="bufferbloat-grade-component">
        <div className="grade-header">
          <h4>BUFFERBLOAT GRADE</h4>
        </div>
        <div className="grade-value">
          <h1>{grade}</h1>
        </div>
        <div>
          <p>{this.text_lookup[grade]}</p>
          { grade !== "A+" &&
          <p>
            Read about <a href="#question-2">how to fix bufferbloat issues.</a>
          </p>
          }
        </div>
      </div>
    )
  }
}
