import Pipe from "./pipe";


export default class BytesCollectorPipe extends Pipe {
  constructor() {
    super();
    this.total = 0;
  }

  process(new_value, data) {
    this.total += new_value;
    this.out([Date.now(), this.total]);
  }
}


