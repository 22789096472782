import TimeUtility from "../utilities/time_utility";
import {RuntimeEnvironment} from "../utilities/runtime_environment";
import LatencySourceSelector, {LatencyMethodLookup} from "./latency_source_selector.js";
import {DataDispatcher} from "../test_runner/data_dispatcher.js";
import {DebugDataSource} from "../test_runner/data_source.js";
import {LatencySourceNotFoundError} from "../utilities/errors.js";
import {AppConfiguration} from "../app_configuration.js";


export default class LatencyCollector {
  constructor(interval, interval_cb, done_cb) {
    this.interval = interval || (() => {});
    this.interval_cb = interval_cb || (() => {});
    this.done_cb = done_cb || (() => {});
    this.cancelled = false;
  }

  async measure_latency() {
    throw new LatencySourceNotFoundError(
      "This method is meant to be set by this." + this.select_correct_source.name
    );
  }

  async select_correct_source() {
    const selector = new LatencySourceSelector();
    const source = await selector.select_latency_source();
    console.debug("source", source);
    DataDispatcher.broadcast_to(DebugDataSource.LATENCY_MEASUREMENT_SOURCE, source);
    this.measure_latency = LatencyMethodLookup[source];
  }

  async start() {
    await this.select_correct_source();
    await this.measure_latencies();
  }

  async measure_latencies() {
    let requests = [],
      counter = 0;
    while(!this.cancelled) {
      counter %= AppConfiguration.NUM_LATENCY_STREAMS - 1;
      counter += 1;
      const promise = this.measure_latency(counter).then(info => {
        if (this.cancelled) {
          console.debug("ignoring this measurement cuz cancelled", info);
          return;
        }
        if (!info) {
          return; // invalid measurement
        }
        let {start, measurement} = info;
        console.debug("latency measurement", measurement);
        this.interval_cb([this.fuzz_value(measurement), start]);
      });
      requests.push(promise);
      await TimeUtility.sleep(this.interval)
    }
    await Promise.all(requests);
    if (this.done_cb) {
      this.done_cb();
    }
  }

  fuzz_value(measurement) {
    if (!RuntimeEnvironment.is_chrome) {
      measurement += (Math.random() - 0.5);
      measurement = measurement > 0 ? measurement : 0;
    }
    return measurement;
  }

  cancel() {
    console.debug("This got cancelled");
    this.cancelled = true;
  }
}
