import TimeUtility from "../utilities/time_utility.js";

export default class LatencyUtililties {
  static async measure_url_diff(url, headers) {
    const async_runnable = async () => {
      const res = await fetch(url, {
        headers: {...headers}
      });
      await res.text();
      return res;
    };
    const {start, duration, result} = await TimeUtility.time_this_runnable(async_runnable);
    console.debug("diff", duration);
    return {start, duration, result, measurement: duration};
  }

  static async measure_diff_minus_server_timing(url, headers) {
    let info = await this.measure_url_diff(url, headers);
    const server_timing = this.measure_server_timing(info.result);
    if (!server_timing) {
      return null;
    }
    return {...info, server_timing, measurement: info.duration - server_timing};
  }

  static async measure_url_ttfb(url, headers)  {
   let {start, result, duration} = await this.measure_url_diff(url, headers);
   const info = TimeUtility.get_ttfb_for(url);
   return {start,  result, duration, measurement: info.ttfb, ...info};
  }

  static measure_server_timing(result) {
    console.log("CF-Cache-Status", result.headers.get("CF-Cache-Status"))
    const server_timing_str = result.headers.get("server-timing");
    if (!server_timing_str) {
      return null;
    }
    const matches = server_timing_str.match(/dur=([0-9.]+)/);
    console.log("server timing", matches[1]);
    return parseFloat(matches[1]);
  }

  static async measure_url_rtt(url, headers) {
    let {start, ttfb, result, perf, duration} = await this.measure_url_ttfb(url, headers);
    const server_timing = this.measure_server_timing(result);
    if (!server_timing) {
      return null;
    }
    const rtt = ttfb - server_timing;
    return {start, ttfb, result, perf, server_timing, rtt, duration, measurement: rtt};
  }

  static async measure_url_download_time(url, headers) {
    const info = await this.measure_url_ttfb(url, headers);
    info.measurement = info.download_time;
    return info;
  }
}
