import React, {Component} from 'react';
import {FormGroup, Row, Col, ControlLabel, FormControl, Form, Button} from "react-bootstrap";
import {AppConfiguration} from "../app_configuration";
import {reset_app_context, TestContext} from "../stages/context";
import {DataDispatcher} from "../test_runner/data_dispatcher";
import {CalculatedDataSource} from "../test_runner/data_source";
import "./share_results_component.scss"
import DownloadTestDataComponent from "./download_test_data_component";
import CopyButton from "./copy_button.js";


export default class ShareResultsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test_id: null
    };
    this.subscriptions = [];
  }

  copy_to_clipboard(e) {
    document.querySelector('#shareResults').select();
    document.execCommand('copy');
  }

  test_query(test_id) {
    return `${AppConfiguration.TEST_ID_QUERY}=${test_id}`;
  }

  componentDidMount() {
    let id = DataDispatcher.subscribe_to(CalculatedDataSource.SAVED_TEST_UUID, (uuid) => {
      window.history.replaceState("", "", `?${this.test_query(uuid)}`);
      this.setState({
        test_id: uuid
      });
    });
    this.subscriptions.push([CalculatedDataSource.SAVED_TEST_UUID, id]);
  }

  componentWillUnmount() {
    for (let [channel, id] of this.subscriptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }

  async test_again() {
    await this.setState({
      test_id: null
    });
    reset_app_context();
    this.props.test_again();
  }

  render() {
    const test_id = this.state.test_id;
    if (!test_id) {
      return (<div className="no-results"></div>);
    }
    const create_url = (test_id) => `${window.location.origin}${window.location.pathname}?${this.test_query(test_id)}`;
    return (
      <div id={`share-results`}>
        <Row>
          <Col xs={12}>
            <Form inline>
              <FormGroup controlId="shareResults">
                <ControlLabel>
                  Share<span> Your Results</span>:
                </ControlLabel>
                <FormControl type="text" readOnly value={create_url(test_id)}/>
                <CopyButton
                  when_tapped={this.copy_to_clipboard.bind(this)}
                />
                <DownloadTestDataComponent
                  test_id={this.state.test_id}
                />
                <div className="test-again big-screen">
                  <Button bsStyle="primary" onClick={this.test_again.bind(this)}>
                    Test Again
                  </Button>
                </div>
              </FormGroup>
            </Form>
            <div className="test-again small-screen">
              <Button bsStyle="primary" onClick={this.test_again.bind(this)}>
                Test Again
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

ShareResultsComponent.contextType = TestContext;
