import URLBuilder from "./url_builder.js";
import LatencyUtililties from "./latency_utilities.js";
import {AppConfiguration} from "../app_configuration.js";

const no_cache_headers = {
  'Cache-Control': 'no-cache',
};

const CACHE_HEADER = "CF-Cache-Status";
const NUM_WARMUP_TRIES = 3;

export default class CfCachingFlyLatencyCollector {
  static async warmup_all_cflare_cached_latency_endpoints() {
    let num_tries = 0;
    while(num_tries < NUM_WARMUP_TRIES) {
      num_tries += 1;
      const promises = Array(AppConfiguration.NUM_LATENCY_STREAMS).fill(0).map((_, i) => {
        return CfCachingFlyLatencyCollector.get_diff(i);
      });
      try {
        const results = await Promise.all(promises);
        const headers = results.map(r => r.result)
          .map(r => r.headers.get(CACHE_HEADER));
        const hits = headers
          .filter(s => s !== "DYNAMIC");
        console.debug("hits", hits);
        console.debug("cache headers", headers);
        if (hits.length > 0)  {
          return true;
        }
      } catch (e) {
        console.error("error getting cloudflare latencies", e);
        return false;
      }
    }
    return false;
  }

  static async get_diff(index) {
    const url = URLBuilder.get_cf_cached_url(index);
    return await LatencyUtililties.measure_diff_minus_server_timing(url, no_cache_headers);
  }

  static async get_rtt(index) {
    const url = URLBuilder.get_cf_cached_url(index);
    return await LatencyUtililties.measure_url_rtt(url, no_cache_headers);
  }
}
