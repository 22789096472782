import ManyChunksBandwidthCollector from "./many_chunks_bandwidth_collector";
import {DebugDataSource} from "../test_runner/data_source";
import TimeUtility from "../utilities/time_utility";
import URLBuilder from "./url_builder.js";


export default class ManyChunksUploadCollector extends ManyChunksBandwidthCollector {
  speed_estimation_debug_channel = DebugDataSource.UPLINK_SPEED_ESTIMATION;
  ESTIMATION_TIMEOUT = 4e3;

  async time_this_resources_transaction(url, async_runnable) {
    return await TimeUtility.time_this_resources_upload(url, async_runnable);
  }

  constructor(measurement_cb, estimation_done_cb) {
    super(measurement_cb, estimation_done_cb);
    this.body_lookup = {}
  }

  get_method_and_url_and_body(size, i) {
    if (!this.body_lookup[size]) {
      this.body_lookup[size] = new Blob(["0".repeat(size)]);
    }
    return ["POST", URLBuilder.get_upload_url(i), this.body_lookup[size]]
  }
}
