import Stage from "./stage";
import ManyChunksUploadCollector from "../tools/many_chunks_upload_collector";
import {DebugDataSource, RealtimeDataSource} from "../test_runner/data_source";
import BandwidthMeasurementDispatcher from "./bandwidth_measurement_dispatcher";
import {AppConfiguration} from "../app_configuration";


export default class UplinkMeasurementStage extends Stage {
  async run() {
    super.run();
    const dispatcher = new BandwidthMeasurementDispatcher();
    await dispatcher.run(
      RealtimeDataSource.UPLINK_LATENCY,
      RealtimeDataSource.UPLINK_BYTES,
      RealtimeDataSource.UPLINK_BANDWIDTH,
      DebugDataSource.UPLINK_WARMUP,
      ManyChunksUploadCollector,
      AppConfiguration.UPLOAD_DURATION
    );
  }
}