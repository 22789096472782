import URLBuilder from "./url_builder.js";
import LatencyUtililties from "./latency_utilities.js";
import {BufferbloatError} from "../utilities/errors.js";
import {AppConfiguration} from "../app_configuration.js";

const MAX_WARMUP_RETRIES = 4;
const no_cache_headers = {
  'Cache-Control': 'no-cache',
};

export default class FastlyLatencyCollector {
  static async warmup_fastly_conection(index) {
    let warmup_retries = 0;
    while (warmup_retries < MAX_WARMUP_RETRIES) {
      warmup_retries += 1;
      const {result, url} = await FastlyLatencyCollector.get_fastly_ttfb(index);
      if (result.headers.get("X-Cache") === "HIT") {
        console.debug(`${url} warmed up`);
        return true;
      }
    }
    throw new BufferbloatError(`Couldn't get a cache hit after ${MAX_WARMUP_RETRIES} retries.`);
  }

  static async warmup_all_fastly_connections() {
    const promises = [];
    for (let i = 0; i < AppConfiguration.NUM_LATENCY_STREAMS; i++) {
      promises.push(FastlyLatencyCollector.warmup_fastly_conection(i));
    }
    return Promise.all(promises);
  }

  static async get_fastly_diff(index) {
    const url = URLBuilder.get_fastly_url(index);
    return await LatencyUtililties.measure_url_diff(url, no_cache_headers);
  }

  static async get_fastly_ttfb(index) {
    const url = URLBuilder.get_fastly_url(index);
    const info = await LatencyUtililties.measure_url_ttfb(url, no_cache_headers);
    return {...info, url};
  }
}

