import Pipe from "./pipe";
import {DataDispatcher} from "../test_runner/data_dispatcher";


export default class BroadcastingPipe extends Pipe {
  constructor(channel) {
    super();
    this.channel = channel;
  }

  process(new_value, data) {
    DataDispatcher.broadcast_to(this.channel, new_value);
    this.out(new_value);
  }
}

