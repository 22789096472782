import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import {AppConfiguration} from "../app_configuration.js";
import TimeUtility from "../utilities/time_utility.js";


const COPY = "Copy";
const COPIED = "Copied!";

export default class CopyButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button_text: COPY
    };
  }

  async on_tapped() {
    this.props.when_tapped();
    await this.setState({ button_text: COPIED });
    await TimeUtility.sleep(1000);
    await this.setState({ button_text: COPY });
  }

  render() {
    return (
      <Button
        className="copy-button"
        onClick={this.on_tapped.bind(this)}
        disabled={this.state.button_text === COPIED}
      >
        {this.state.button_text}
        <img src={AppConfiguration.STATIC_RESOURCES_PATH + "/images/copy.svg"} alt="copy-icon"/>
      </Button>
    )
  }
}