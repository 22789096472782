import LatencyUtililties from "./latency_utilities.js";
import FastlyLatencyCollector from "./fastly_latency_collector.js";
import {RuntimeEnvironment} from "../utilities/runtime_environment.js";
import {AppContextObject} from "../stages/context.js";
import {AppConfiguration} from "../app_configuration.js";
import StatisticsUtilities from "../utilities/statistics_utilities.js";
import CfWorkersLatencyCollector from "./cf_workers_latency_collector.js";
import CfCachingFlyLatencyCollector from "./cf_caching_fly_latency_collector.js";
import CFlareLatencyCollector from "./cflare_latency_collector.js";

export const LatencySource = {
  FASTLY_DIFF: "fastly_diff",
  FASTLY_TTFB: "fastly_ttfb",
  GOOGLE_FONTS_DIFF: "google_fonts_diff",
  GOOGLE_FONTS_TTFB: "google_fonts_ttfb",
  CLOUDFLARE_DIFF: "cloudflare_diff",
  CLOUDFLARE_RTT: "cloudflare_rtt",
  CFLARE_WORKER_DIFF: "cflare_worker_diff",
  CFLARE_WORKER_RTT: "cflare_worker_rtt",
  CFLARE_CACHING_FLY_DIFF: "cflare_caching_fastly_diff",
  CFLARE_CACHING_FLY_RTT: "cflare_caching_fastly_rtt",
};

export const LatencyMethodLookup = {
  [LatencySource.FASTLY_DIFF]: FastlyLatencyCollector.get_fastly_diff,
  [LatencySource.FASTLY_TTFB]: FastlyLatencyCollector.get_fastly_ttfb,
  [LatencySource.GOOGLE_FONTS_DIFF]: LatencyUtililties.get_google_fonts_diff,
  [LatencySource.GOOGLE_FONTS_TTFB]: LatencyUtililties.get_google_fonts_ttfb,
  [LatencySource.CLOUDFLARE_DIFF]: CFlareLatencyCollector.get_cloudflare_diff,
  [LatencySource.CLOUDFLARE_RTT]: CFlareLatencyCollector.get_cloudflare_rtt,
  [LatencySource.CFLARE_WORKER_DIFF]: CfWorkersLatencyCollector.get_cloudflare_diff,
  [LatencySource.CFLARE_WORKER_RTT]: CfWorkersLatencyCollector.get_cloudflare_rtt,
  [LatencySource.CFLARE_CACHING_FLY_DIFF]: CfCachingFlyLatencyCollector.get_diff,
  [LatencySource.CFLARE_CACHING_FLY_RTT]: CfCachingFlyLatencyCollector.get_rtt,
};

const resource_timing_supported = () => !(RuntimeEnvironment.is_ios || RuntimeEnvironment.is_safari);
const avg = StatisticsUtilities.avg;
export const NUM_TRIES = 2;

export default class LatencySourceSelector {
  async try_multiple_times(measurer) {
    let tries = 0;
    const all = [];
    while (tries < NUM_TRIES) {
      tries++;
      const promises = [];
      Array(AppConfiguration.NUM_LATENCY_STREAMS).fill(0).map((_, i) => {
        promises.push(measurer(i));
      });
      const values = await Promise.all(promises);
      all.push(...values.map(v=>v.measurement));
    }
    return all;
  }

  async select_between_fastly_and_cloudflare(supported) {
    const sources = (supported) ? [
      CfCachingFlyLatencyCollector.get_rtt,
      FastlyLatencyCollector.get_fastly_ttfb
    ] : [
      CfCachingFlyLatencyCollector.get_diff,
      FastlyLatencyCollector.get_fastly_diff
    ];
    const promises = [
      this.try_multiple_times(sources[0]),
      this.try_multiple_times(sources[1])
    ];
    const [values_cf, values_fastly] = await Promise.all(promises);
    const [avg_cf, avg_fastly] = [values_cf, values_fastly].map(v => avg(v));
    console.debug("avg_cf", avg_cf, "values_cf", values_cf);
    console.debug("avg_fastly", avg_fastly, "values_fastly", values_fastly);
    const cf_better = avg_cf < avg_fastly;
    if (supported) {
      return cf_better ? LatencySource.CFLARE_CACHING_FLY_RTT : LatencySource.FASTLY_TTFB
    } else {
      return cf_better ? LatencySource.CFLARE_CACHING_FLY_DIFF : LatencySource.FASTLY_DIFF
    }
  }

  async select_latency_source() {
    if (AppContextObject.latency_source) {
      console.debug(`Using previously selected latency source: ${AppContextObject.latency_source}`);
      return AppContextObject.latency_source;
    }
    await FastlyLatencyCollector.warmup_all_fastly_connections();
    await CfCachingFlyLatencyCollector.warmup_all_cflare_cached_latency_endpoints();
    let supported = resource_timing_supported();
    const source = await this.select_between_fastly_and_cloudflare(supported);
    console.debug(`Selected latency source: ${source}`);
    AppContextObject.latency_source = source;
    return source;
  }
}
