import {AppConfiguration, BufferbloatMeasurementMethod} from "../app_configuration.js";
import {AppContextObject} from "../stages/context.js";

const VERSION_MEAN_CALCULATION_INTRODUCED = "1.0.7";

export function is_median() {
  if (!AppContextObject.loaded_test) {
    return AppConfiguration.BUFFERBLOAT_MEASUREMENT_METHOD === BufferbloatMeasurementMethod.MEDIAN;
  }
  if (AppContextObject.loaded_test.current_test_version < VERSION_MEAN_CALCULATION_INTRODUCED) {
    return true;
  }
  return (AppContextObject.loaded_test.bufferbloat_method === BufferbloatMeasurementMethod.MEDIAN);
}
