import React, {Component} from 'react';
import "./download_test_data_component.scss"
import CSVGenerator from "../utilities/csv_generator";
import {AppConfiguration} from "../app_configuration";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {TooltipWrapper} from "./tooltip_wrapper.js";


export default class DownloadTestDataComponent extends Component {
  async download() {
    const base = `${AppConfiguration.WORKER_URL}/test-results-csv`;
    const url = `${base}?test-id=${this.props.test_id}`;
    const file_name = `waveform_com_bufferbloat_test_results_${this.props.test_id}.csv`;
    await CSVGenerator.generate_csv_from_url(url, file_name);
  }

  render() {
    return (
      <div className="download-test-data-component">
        <TooltipWrapper
          tooltip_text={"Download raw test result data as a CSV"}
        >
          <Button>
            <FontAwesomeIcon
              onClick={this.download.bind(this)}
              size={"md"}
              icon={faDownload} />
          </Button>
        </TooltipWrapper>
      </div>
    )
  }
}
