import {AppConfiguration} from "../app_configuration";

export default class TestResultsManager {
  static TESTS_ENDPOINT = `${AppConfiguration.WORKER_URL}/test-results`;
  static get_test_link = (test_id) => `${TestResultsManager.TESTS_ENDPOINT}?test-id=${test_id}`;

  static async get_test_results(test_id) {
    const test_url = this.get_test_link(test_id);
    console.debug(`fetching test from: ${test_url}`);
    let res = await fetch(test_url, {
      method: "GET"
    });
    return await res.json();
  }

  static async post_results(test_results) {
    let res = await fetch(TestResultsManager.TESTS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(test_results)
    });
    return await res.text();
  }
}
