import {CalculatedDataSource, DebugDataSource, RealtimeDataSource} from "../test_runner/data_source";
import {DataDispatcher} from "../test_runner/data_dispatcher";
import {RuntimeEnvironment} from "../utilities/runtime_environment";
import TestResultsManager from "../tools/test_results_manager";
import {AppConfiguration} from "../app_configuration";
import {get_geoip_info} from "../utilities/geoip_info_collector.js";


export default class TestResultPublisher {
  constructor() {
    this.test_result = {
      test_data: null,
      box_plot_scale: null,
      downlink_bandwidth: null,
      uplink_bandwidth: null,
      is_mobile: null,
      system_info: null,
      test_report: null,
      debug_data: {},
      date: Date.now(),
      version: AppConfiguration.RESULTS_SCHEMA_VERSION
    };
    this.subscriptions = [];
  }

  start() {
    this.subscribe_for_test_data();
    this.subscribe_for_report_data();
    this.subscribe_for_bandwidths();
    this.subscribe_for_debug();
  }

  subscribe_for_debug() {
    for (const source of [
      DebugDataSource.DOWNLINK_WARMUP,
      DebugDataSource.UPLINK_WARMUP,
      DebugDataSource.LATENCY_MEASUREMENT_SOURCE
    ]) {
      const id = DataDispatcher.subscribe_to(source, value => {
        this.test_result.debug_data[source] = value;
      });
      this.subscriptions.push([source, id]);
    }
  }

  publish_if_received_everything() {
    if (this.test_result.test_report && this.test_result.test_data) {
      this.publish().then();
    }
  }

  subscribe_for_report_data() {
    const id = DataDispatcher.subscribe_to(CalculatedDataSource.REPORT, report => {
      this.test_result.test_report = report;
      this.publish_if_received_everything();
    });
    this.subscriptions.push([CalculatedDataSource.REPORT, id]);
  }

  subscribe_for_test_data() {
    let id = DataDispatcher.subscribe_to(RealtimeDataSource.AGGREGATED_TEST_DATA, test_data => {
      this.test_result.test_data = {
        ...test_data.stages
      };
      this.test_result.debug_data = {
        ...this.test_result.debug_data,
        ...test_data.debug_data
      };
      this.publish_if_received_everything();
    });
    this.subscriptions.push([RealtimeDataSource.AGGREGATED_TEST_DATA, id]);
  }

  subscribe_for_bandwidths() {
    for (const [source, variable] of [
      [RealtimeDataSource.DOWNLINK_BANDWIDTH, "downlink_bandwidth"],
      [RealtimeDataSource.UPLINK_BANDWIDTH, "uplink_bandwidth"]
    ]) {
      const id = DataDispatcher.subscribe_to(source, (value) => {
        this.test_result[variable] = value;
      });
      this.subscriptions.push([source, id]);
    }
  }

  unsubscribe_from_everything() {
    for (let [channel, id] of this.subscriptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }

  fill_out_system_info() {
    this.test_result.is_mobile = RuntimeEnvironment.is_mobile;
    const navigator_keys = ["appCodeName", "appName", "appVersion", "platform", "language", "product", "productSub",
      "userAgent", "vendor"];
    let system_info = {};
    for (const key of navigator_keys) {
      system_info[key] = navigator[key];
    }
    return system_info;
  }

  get_latencies(test_data) {
    return Object.keys(test_data).map(k => {
      return test_data[k].latencies
    });
  }

  get_all_latencies(test_data) {
    return this.get_latencies(test_data)
      .reduce((arr, new_arr) => {
        return arr.concat(new_arr);
      }, [])
      .map(v => v[0]);
  }

  calculate_box_plot_scale(test_data) {
    let latencies = this.get_all_latencies(test_data);
    return [
      Math.min(...latencies),
      Math.max(...latencies)
    ];
  }

  async publish() {
    this.test_result.system_info = this.fill_out_system_info();
    this.test_result.box_plot_scale = this.calculate_box_plot_scale(this.test_result.test_data);
    this.test_result.geoip_info = await get_geoip_info();
    this.test_result.bufferbloat_method = AppConfiguration.BUFFERBLOAT_MEASUREMENT_METHOD;
    console.debug("uploaded test result", this.test_result);
    const test_uuid = await TestResultsManager.post_results(this.test_result);
    DataDispatcher.broadcast_to(CalculatedDataSource.SAVED_TEST_UUID, test_uuid);
    this.unsubscribe_from_everything();
  }
}
