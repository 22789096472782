import URLBuilder from "./url_builder.js";
import LatencyUtililties from "./latency_utilities.js";
import {AppConfiguration} from "../app_configuration.js";

export default class CFlareLatencyCollector {
  static async warmup_all_cflare_workers_connections() {
    const promises = Array(AppConfiguration.NUM_LATENCY_STREAMS).fill(0).map((_, i) => {
      return CFlareLatencyCollector.get_cloudflare_diff(i);
    });
    await Promise.all(promises);
  }

  static async get_cloudflare_diff() {
    const url = URLBuilder.get_cloudflare_ping_url();
    return await LatencyUtililties.measure_diff_minus_server_timing(url);
  }
  static async get_cloudflare_rtt() {
    const url = URLBuilder.get_cloudflare_ping_url();
    return await LatencyUtililties.measure_url_rtt(url);
  }
}
