import {DataDispatcher} from "../test_runner/data_dispatcher";
import {AppEvent, DebugDataSource, RealtimeDataSource} from "../test_runner/data_source";
import {Stages} from "../stages/stages";


export default class TestDataCollector {
  DEBUG_DATA = "debug_data";

  constructor() {
    this.subscrptions = [];
  }

  start() {
    this.test_data = {
      stages: {
        [Stages.UNLOADED]: {latencies: []},
        [Stages.DOWNLINK]: {latencies: [], bytes: []},
        [Stages.UPLINK]: {latencies: [], bytes: []},
      },
      [this.DEBUG_DATA]: {}
    };
    this.subscribe_for_latencies();
    this.subscribe_for_bytes();
    this.subscribe_for_end_of_test_event();
    this.subscribe_for_debug_data();
  }

  subscribe_for_debug_data() {
    const set_it = (direction) => ((estimation_debug_data) => {
      this.test_data[this.DEBUG_DATA][direction] = estimation_debug_data
    });
    for (const source of [
      DebugDataSource.DOWNLINK_SPEED_ESTIMATION,
      DebugDataSource.UPLINK_SPEED_ESTIMATION
    ]) {
      let id = DataDispatcher.subscribe_to(source, set_it(source));
      this.subscrptions.push([source, id])
    }
  }

  subscribe_for_bytes() {
    for (const [source, stage] of [
      [RealtimeDataSource.DOWNLINK_BYTES, Stages.DOWNLINK],
      [RealtimeDataSource.UPLINK_BYTES, Stages.UPLINK]
    ]) {
      let id = DataDispatcher.subscribe_to(source, (measurement) => {
        this.test_data.stages[stage].bytes.push(measurement);
      });
      this.subscrptions.push([source, id]);
    }
  }

  subscribe_for_latencies() {
    for (const [source, stage] of [
      [RealtimeDataSource.UNLOADED_LATENCY, Stages.UNLOADED],
      [RealtimeDataSource.UPLINK_LATENCY, Stages.UPLINK],
      [RealtimeDataSource.DOWNLINK_LATENCY, Stages.DOWNLINK]
    ]) {
      let id = DataDispatcher.subscribe_to(source, (measurement) => {
        this.test_data.stages[stage].latencies.push(measurement);
      });
      this.subscrptions.push([source, id]);
    }
  }

  subscribe_for_end_of_test_event() {
    let id = DataDispatcher.subscribe_to(AppEvent.FINISHED_STAGE, this.make_report.bind(this));
    this.subscrptions.push([AppEvent.FINISHED_STAGE, id]);
  }

  unsubscribe_from_everything() {
    for (let [channel, id] of this.subscrptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }

  make_report(stage) {
    if (stage !== Stages.DONE) {
      return;
    }
    DataDispatcher.broadcast_to(RealtimeDataSource.AGGREGATED_TEST_DATA, this.test_data);
    this.unsubscribe_from_everything();
  }
}
