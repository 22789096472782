export const LARGER_THAN = "LARGER_THAN";
export const SMALLER_THAN = "SMALLER_THAN";

export const DOWNLINK = "downlink";
export const UPLINK = "uplink";
export const LATENCY = "latency";
export const LATENCY_95TH = "latency_95th"; // 95th percentile
export const JITTER = "jitter";

export const Categories = [
  // sources:
  // 1. https://docs.google.com/document/d/1miS-bB24btLTbw2IdFSZtfKHee0eq53TrOL8iCihZgI/edit
  {
    name: "Web Browsing",
    criteria: {
      [DOWNLINK]: [LARGER_THAN, 2e6],
      [UPLINK]: [LARGER_THAN, 1e5],
      [LATENCY]: [SMALLER_THAN, 500],
    }
  },
  {
    name: "Audio Calls",
    criteria: {
      [DOWNLINK]: [LARGER_THAN, 1e5],
      [UPLINK]: [LARGER_THAN, 1e5],
      [LATENCY_95TH]: [SMALLER_THAN, 400],
    }
  },
  {
    name: "4K Video Streaming",
    criteria: {
      [DOWNLINK]: [LARGER_THAN, 25e6]
    }
  },
  {
    name: "Video Conferencing",
    criteria: {
      [DOWNLINK]: [LARGER_THAN, 10e6],
      [UPLINK]: [LARGER_THAN, 5e6],
      [LATENCY_95TH]: [SMALLER_THAN, 400],
    }
  },
  {
    name: "Low Latency Gaming",
    criteria: {
      [DOWNLINK]: [LARGER_THAN, 10e6],
      [UPLINK]: [LARGER_THAN, 3e6],
      [LATENCY_95TH]: [SMALLER_THAN, 40],
    }
  }
];


export const BufferbloatGradings =  [
  {
    until: 5,
    grade: "A+"
  },
  {
    until: 30,
    grade: "A"
  },
  {
    until: 60,
    grade: "B"
  },
  {
    until: 200,
    grade: "C"
  },
  {
    until: 400,
    grade: "D"
  },
  {
    until: Infinity ,
    grade: "F"
  }
];
