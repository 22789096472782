import React from "react";
import {FAQs} from "./main_page_faqs.js";
import {renderToString} from "react-dom/server";


// more info here:

export function get_json_ld_script() {
  const json_ld = {
    "@context": "https://schema.org",
    "@type": "FAQPage"
  };
  json_ld["mainEntity"] = FAQs.map(faq => {
    return {
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": renderToString(faq.answer)
      }
    }
  });
  return (
    <script type="application/ld+json">
      {JSON.stringify(json_ld)}
    </script>
  )
}
