import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import LatencyStatsComponent from "./latency_stats_component";
import './latency_stage_report.scss';
import LatencyBoxPlot from "./latency_box_plot";
import ScreenUtilities from "../utilities/screen_utilities";
import {DataDispatcher} from "../test_runner/data_dispatcher";
import {Stages} from "../stages/stages";
import {LoadedDataSource, RealtimeDataSource} from "../test_runner/data_source";


export default class LatencyStageReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
    this.realtime_source_lookup = {
      [Stages.UNLOADED]: RealtimeDataSource.UNLOADED_LATENCY,
      [Stages.DOWNLINK]: RealtimeDataSource.DOWNLINK_LATENCY,
      [Stages.UPLINK]: RealtimeDataSource.UPLINK_LATENCY
    };
    this.loaded_source_lookup = {
      [Stages.UNLOADED]: LoadedDataSource.LOADED_UNLOADED_LATENCIES,
      [Stages.DOWNLINK]: LoadedDataSource.LOADED_DOWNLINK_LATENCIES,
      [Stages.UPLINK]: LoadedDataSource.LOADED_UPLINK_LATENCIES
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    const [rt_channel, loaded_channel] = [
      this.realtime_source_lookup[this.props.stage],
      this.loaded_source_lookup[this.props.stage]
    ];
    let sub_id = DataDispatcher.subscribe_to(rt_channel, ([latency, timestamp]) => {
      let data = this.state.data;
      data.push({value:latency, timestamp: timestamp});
      this.setState({data});
    });
    this.subscriptions.push([rt_channel, sub_id]);
    sub_id = DataDispatcher.subscribe_to(loaded_channel, (data) => {
      this.setState({data});
    });
    this.subscriptions.push([loaded_channel, sub_id]);
  }

  componentWillUnmount() {
    for (const [channel, id] of this.subscriptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }

  render() {
    return (
      <>
        {
          (this.props.spacer_before) ?
            (<div className={"spacer"}>
            </div>)
            : (<></>)
        }
        <div className="latency-stage-report">
          <div className={"title"}>
            <h5>{this.props.title}</h5>
          </div>
          <div className="box-plot-container">
            {(this.state.data.length > 0) ? (
              <LatencyBoxPlot
                data={this.state.data}
                scale={this.props.box_plot_scale}
              />
            ): (
              <p className={"warming-up"}>Warming Up...</p>
            )}
          </div>
          <div className={"stage-latency-stats"} >
            <LatencyStatsComponent
              stage={this.props.stage}
            />
          </div>
        </div>
      </>
    );
  }
}
