import React, {Component} from 'react';
import "./speed_banner.scss"
import {LoadedDataSource, RealtimeDataSource} from "../test_runner/data_source";
import {DataDispatcher} from "../test_runner/data_dispatcher";
import {TestContext} from "../stages/context";


export default class SpeedBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stages: [
        {
          title: "↓ Download",
          realtime_data_source: RealtimeDataSource.DOWNLINK_BANDWIDTH,
          loaded_data_source: LoadedDataSource.LOADED_DOWNLINK_SPEED,
          value: null
        },
        {
          title: "↑ Upload",
          realtime_data_source: RealtimeDataSource.UPLINK_BANDWIDTH,
          loaded_data_source: LoadedDataSource.LOADED_UPLINK_SPEED,
          value: null
        }
      ]
    };
    this.title = "speed";
    this.subscriptions = [];
  }


  format_bandwidth(bandwidth) {
    if (bandwidth < 1e6) {
      bandwidth = bandwidth/1e3;
      return [bandwidth.toFixed(1), "Kbps"];
    }
    bandwidth = bandwidth/1e6;
    if (bandwidth > 10) {
      return [bandwidth.toFixed(1), "Mbps"];
    }
    return [bandwidth.toFixed(2), "Mbps"];
  }

  componentDidMount() {
    for (const idx in this.state.stages) {
      const stage = this.state.stages[idx];
      const update_bandwidth = (bandwidth) => {
        [stage.value, stage.postfix] = this.format_bandwidth(bandwidth);
        this.setState({
          stages: this.state.stages
        })
      };
      let id = DataDispatcher.subscribe_to(stage.realtime_data_source, update_bandwidth);
      this.subscriptions.push([stage.realtime_data_source, id]);
      id = DataDispatcher.subscribe_to(stage.loaded_data_source, update_bandwidth);
      this.subscriptions.push([stage.loaded_data_source, id]);
    }
  }

  componentWillUnmount() {
    for (let [channel, id] of this.subscriptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }

  render() {
    const format_value = (value, postfix) => {
      const empty_paragraph = (
        <span style={{color: 'red', visibility: 'hidden'}}>hello</span>
      );
      if (!value) {
        return empty_paragraph;
      }
      return (<>
        {value}
        <span> {postfix}</span>
      </>);
    };

    const bandwidth_number = (stage, index) => {
      const focused = (index + 2 === this.context.current_stage_idx);
      if (focused && !stage.value) {
        return (
          <p className={"warming-up"}>Warming Up...</p>
        )
      }
      return (
        <p>
          {format_value(stage.value, stage.postfix)}
        </p>
      );
    };

    return (
      <div className="speed-banner banner">
        <h3>{this.title.toUpperCase()}</h3>
        <div className="callout-container">
          { this.state.stages
            .map((stage, index) => (
              <div className="callout speed-callout" key={index}>
                <h4>{stage.title}</h4>
                {bandwidth_number(stage, index)}
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}

SpeedBanner.contextType = TestContext;
