import React from "react";
import {
  Categories,
  DOWNLINK,
  JITTER, LARGER_THAN,
  LATENCY, LATENCY_95TH,
  SMALLER_THAN,
  UPLINK
} from "../report/report_generation_criteria.js";


function create_criteria_view() {
  const criterion_name_lookup = {
    [DOWNLINK]: "Download speed",
    [UPLINK]: "Upload speed",
    [LATENCY]: "Latency",
    [LATENCY_95TH]: "95th Percentile Latency",
    [JITTER]: "Jitter"
  };

  const symbol_lookup = {
    [SMALLER_THAN]: "<",
    [LARGER_THAN]: ">"
  };

  const format_value = (criterion_name, value) => {
    const format_bandwidth = (value) =>  {
      for (const [limit, postfix] of [
        [1e6, "Mbps"],
        [1e3, "Kbps"],
        [0, "bps"]
      ]) {
        if (value >= limit) {
          return `${(value/limit).toFixed(0)} ${postfix}`;
        }
      }
    };
    if ([LATENCY, JITTER, LATENCY_95TH].includes(criterion_name)) {
      return `${value} ms`;
    }
    return format_bandwidth(value);
  };

  const create_criterion_view = (criterion_name, criterion, i) => {
    if (!criterion) {
      return null;
    }
    return (
      <li key={i}>
        {criterion_name_lookup[criterion_name]} {' '}
        {symbol_lookup[criterion[0]]} {' '}
        {format_value(criterion_name, criterion[1])}
      </li>
    )
  };

  const create_category_view = (cat, i) => {
    return (
      <li key={i}>
        {cat.name}:
        <ul>
          {Object.keys(cat.criteria)
            .map((criterion_name, i) => create_criterion_view(
              criterion_name,
              cat.criteria[criterion_name],
              i
            ))}
        </ul>
      </li>
    )
  };

  return (
    <ul>
      {Categories.map((cat, i) => create_category_view(cat, i))}
    </ul>
  )
}


export const FAQs = [
  {
    question: "Can you explain bufferbloat like I’m five?",
    answer: (
      <>
        <p>
          Imagine that you have a sink with a very narrow drain. Your friend pours a large bucket of water into it. The sink is full and draining very slowly.
        </p>
        <p>
          You have a spoonful of oil that you want to get down the drain as soon as possible. You pour it into the sink. But of course, it doesn’t drain quickly - the water in the sink needs to drain first. Shoot!
        </p>
        <p>
          The sink is a buffer - a “queue” for liquid. When your friend poured in the bucket of water, they filled the queue. Anything new will take a long time to drain.
        </p>
        <p>
          This isn’t a perfect analogy to what’s happening in your router, but it’s close!
        </p>
        <p>
          Instead of water and oil, networks have different flows of packets. Your router is like the sink, and your connection to the ISP is the narrow drain (since it’s probably the slowest link in your network).
        </p>
        <p>
          When someone on your network sends a large file, a lot of packets get sent all at once. The router temporarily "buffers those packets", holding them before they’re sent. Any new data packets get stuck behind the existing queue of buffered packets. They will arrive at the destination much later than if the router’s buffers hadn't been full.
        </p>
        <p>
          This is “bufferbloat” - undesirable high latency caused by other traffic on your network. It happens when a flow uses more than its fair share of the bottleneck. Bufferbloat is the primary cause of bad performance for real-time Internet applications like VoIP calls, video games, and videoconferencing.
        </p>
        <p>
          However, all is not lost!
        </p>
        <p>
          Certain routers have smart algorithms (usually called "SQM") that ensure that time-sensitive packets flowing through the router don’t get delayed, even when large files are being downloaded or uploaded. Continuing with the liquid analogy, these routers offer a way to admit just the right amount of water into the sink so the drain pipe is always full, but a new spoonful of oil will drain out immediately.
        </p>
        <p>
          Read our answers to <a href="#question-2">"How do I get rid of bufferbloat?"</a> and <a href="#question-3">"What router can I buy to fix bufferbloat?"</a> to find out how you can fix bufferbloat.
        </p>
      </>
    )
  },
  {
    question: "What are the negative consequences of having bufferbloat?",
    answer: (
      <>
        <p>
          Bufferbloat can make web browsing slower, make video calls stutter, and cause VoIP calls will break up. Real-time games will lag.
        </p>
        <p>
          Bufferbloat causes degraded connectivity anytime your Internet connection is under heavy use by any user or application. If a large upload or download of data is happening, other applications and users will slow down.
        </p>
        <p>
          How often is someone on your network really under heavy use? You'd be surprised! Many apps are bandwidth-hungrier than you might imagine. For example, most smartphone's photo-taking apps back up all photos and videos to the cloud as soon as they're taken. 
        </p>
      </>
    )
  },
  {
    question: "How do I get rid of bufferbloat?",
    answer: (
      <>
        <p>
          The easiest and quickest way for most people to fix bufferbloat is to upgrade their WiFi router. A small number of modern, high-end routers come with a feature called "Smart Queue Management" – also known as SQM. We've collected a short list of routers with good SQM <a href="/#question-3">here</a>. Once enabled and configured correctly, a router with SQM can get rid of most bufferbloat.
        </p>
        <p>
          There are many different versions of SQM, and some are more effective than others. If you are replacing an old router, any new router with a decent SQM implementation should show a substantial improvement.
        </p>
        <p>
          But if you have a fast Internet connection, and want to get to an A+ grade, it's important to pick a router with a great SQM implemention and a fast CPU. SQM is quite CPU-intensive, so the faster your internet connection, the faster the CPU of your router will need to be in order to keep up.
        </p>
        <p>
          If you already have a relatively new router, check your router’s settings to see if there is an option to enable SQM. Alternatively, for tech-savvy users, it’s possible to replace the firmware running on many routers with third-party software like{' '}
          <a href="https://dd-wrt.com/support/router-database/">
            DD-WRT
          </a>,{' '}
          <a href="https://www.asuswrt-merlin.net/">
            AsusWrt-Merlin
          </a> (for Asus routers), or OpenWrt,
          all of which support SQM.
        </p>
      </>
    )
  },
  {
    question: "What router can I buy to fix bufferbloat?",
    answer: (
      <>
        <p>
          The quickest route to fixing bufferbloat is buying a router with a fast CPU and a great Smart Queue Management implementation. 
        </p>
        <p>
          Devices we recommend include:
        </p>
        <ul>
          <li>
            The
            {' '}
            <a href="https://www.amazon.com/eero-Single-Wireless-Router/dp/B0777K6F8R">
              Eero Pro 5
            </a> 
            {' '}mesh router with Eero Labs SQM enabled is the most user-friendly option that we've found to date. It can perform SQM on connections up to around 350 Mbps.<br />(<em>Please note:</em> the new <a href="https://www.amazon.com/Eero-Pro-6-Router/dp/B085VNCZHL">Eero Pro 6</a> does not support SQM yet, though support is said to be coming soon.)
          </li>
          <li>
            <a href="https://www.amazon.com/IQrouter-Self-Optimizing-Router-Improved-Quality/dp/B07Y8TQ5C6">
              IQRouter IQRV3
            </a> - A router designed specifically to reduce bufferbloat
          </li>
          <li>
            <a href="https://www.amazon.com/NETGEAR-Nighthawk-Gaming-6-Stream-Router/dp/B08FWNNWTB">
                NETGEAR Nighthawk Pro Gaming XR1000
              </a> - Gaming router with DumaOS 3.0 software
          </li>
          <li>
            <p>
              <a href="https://www.amazon.com/Ubiquiti-Networks-ER-4-EdgeRouter-4/dp/B078PGCGN2?sa-no-redirect=1">
                Ubiquiti Edgerouter 4
              </a> - Enterprise grade router (no WiFi) with a more complex user interface, but excellent SQM performance
            </p>
          </li>
        </ul>
      </>
    )
  },
  {
    question: "Should I test bufferbloat over ethernet or WiFi?",
    answer: (
      <>
        <p>Ethernet is generally a better choice.</p>
        <p>Here's why:</p>
        <p>
          Bufferbloat happens any time data packets traveling to and from the Internet reach a buffer. Buffers are used to control the flow of data whenever there is a change in the bandwidth of the conenction, or "the size of the pipe." So, for example, a buffer is needed when a fast router is connected to a slow cable modem.
        </p>
        <p>
          If you're connecting over WiFi, the "size of the pipe" actually changes three times. So there are actually three buffers. Looking at the download path, there is:
        </p>
        <ol>
          <li>A buffer between your cable, DSL, LTE, satellite or fiber modem and your router;</li>
          <li>A buffer between your router and the WiFi access point (which may both be in the same physical box);</li>
          <li>A buffer between your phone or laptop's WiFi chipset and the actual computer.</li>
        </ol>
        <p>
          By connecting with an ethernet cable, you're eliminating the second and third buffers, allowing you to specifically isolate your "modem to router bufferbloat." This is generally the most important buffer since it impacts every device on the network.
        </p>
        <p>
          It's also worth testing WiFi bufferbloat on different devices. If you notice high bufferbloat, it may be worth upgrading either your access points and/or your devices.
        </p>
      </>
    )
  },
  {
    question: "How does bufferbloat affect gaming?",
    answer: (
      <>
        <p>
          Latency is incredibly important for many online games. When your network is suffering from bufferbloat, the latency will spike, causing noticeable delays, or “lag.” Severe bufferbloat will affect your performance against the other players and your enjoyment of the game. Despite this impact, many routers that advertise themselves as “gaming routers” lack the critical feature to address bufferbloat. Be sure to check out our router recommendations above.
        </p>
      </>
    )
  },
  {
    question: "How does bufferbloat affect video calls?",
    answer: (
      <>
        <p>
          If you suffer from bufferbloat during video calls then your call will suffer from delays and occasional dropouts.
        </p>
      </>
    )
  },
  {
    question: "How do you assign letter grades to bufferbloat measurements?",
    answer: (
      <>
        <p>
          Our grading system is a slightly modified version of the <a href="http://www.dslreports.com/faq/17930">DSLReports
          </a> grading rubric.
        </p>
        <p>First we measure the average latency of your connection when it is unloaded. Next we look at how much this average latency increases when a download and upload test are each active. We take the largest increase we measured, and assign a grade as follow:
        </p>
        <ul>
          <li>Less than 5 ms latency increase - A+</li>
          <li>Less than 30 ms latency increase - A</li>
          <li>Less than 60 ms latency increase - B</li>
          <li>Less than 200 ms latency increase - C</li>
          <li>Less than 400 ms latency increase - D</li>
          <li>400 ms or greater latency increase - F</li>
        </ul>
      </>
    )
  },
  {
    question: "How do you determine which services will work well on my connection?",
    answer: (
      <>
        <p>
          We use the following criteria to determine if a particular service will work on your Internet connection. Of course, these criteria are far from perfect, but we think they’re a good general guideline.
        </p>
        {create_criteria_view()}
      </>
    )
  },
  {
    question: "How is this test different to the DSLReports speed test?",
    answer: (
      <>
        <p>
          The {' '}
          <a href="http://www.dslreports.com/tools">
           DSLReports Speed Test
          </a>{' '}
          is an excellent tool, and is superior to this test for some applications. Our goal was to design a test focused primarily on measuring bufferbloat.
        </p>
        <p>There are two major technical differences:</p>
        <ul>
          <li>
            We are using HTTP requests instead of WebSockets for our latency and speed test. This has both advantages and disadvantages in terms of measuring bufferbloat. We hope to improve this test in the future to measure latency both via WebSockets, HTTP requests, and WebRTC Data Channels.
          </li>
          <li>
            We’re using Cloudflare’s CDN to test upload and download speeds. By using a CDN, we increase the likelihood that our speed test will saturate faster connections, no matter where users are located.
          </li>
        </ul>
      </>
    )
  },
  {
    question: "Why do my results vary on different browsers/devices?",
    answer: (
      <>
        <p>
          In order to get our test working properly in different browsers, we had to use a number of different workarounds. Those workarounds may mean that your download and upload speeds or latencies vary by browser. However, your bufferbloat grade and increase in latency under load should be fairly consistent.
        </p>
        <p>
          Here's just one example: on most browsers we use the PerformanceTiming API to measure a ping time from a nearby server. However, that API doesn't work properly in Safari on MacOS or any browser on iOS devices. As a result, we have to measure latency and connection speeds in a completely different way for Safari and other Webkit-based browsers.
        </p>
      </>
    )
  },
  {
    question: "What is jitter? How do you calculate it?",
    answer: (
      <>
        <p>
          Jitter is a measure of the variation in latency over time. If your connection suffers from bufferbloat, you'll often also see higher jitter. Too much jitter can cause issues with realtime video and audio calls and online games.
        </p>
        <p>
          There are a number of different ways of measuring and defining jitter. For the purpose of this test, we calculate jitter by taking the average of the deviations from the mean latency.
        </p>
      </>
    )
  },
  {
    question: "Why did you build this?",
    answer: (
      <>
        <p>
          Bufferbloat is a scourge on the Internet. We’ve struggled with it ourselves far too many times. After our favorite existing test by DSLReports went offline for a few months, we decided it might be helpful for the internet at large if we made a new test focused just on bufferbloat. Our hope is that this tool helps raise awareness about bufferbloat as an issue, and helps convince more router manufacturers to implement Smart Queue Management.
        </p>
      </>
    )
  },
  {
    question: "Where can I read more about bufferbloat?",
    answer: (
      <>
        <p>
          We highly recommend checking out{' '}
          <a href="https://www.bufferbloat.net/">
           bufferbloat.net
          </a>
          , a site dedicated to spreading awareness and propagating software solutions to bufferbloat in networking equipment.
        </p>
      </>
    )
  },
];
