import {DataDispatcher} from "../test_runner/data_dispatcher";
import {CalculatedDataSource, RealtimeDataSource} from "../test_runner/data_source";
import StatisticsUtilities from "../utilities/statistics_utilities";
import {
  Categories,
  DOWNLINK, JITTER,
  LARGER_THAN, LATENCY, LATENCY_95TH,
  SMALLER_THAN,
  UPLINK
} from "./report_generation_criteria";
import {
  check_passed,
  get_important_statistics_as_dict,
  NEW_TABLE_VERSION,
  OLD_TABLE_VERSION
} from "./report_processing.js";
import {
  get_bufferbloat_info,
  get_latencies_from_test_data,
} from "./report_generation_utilities.js";

export const FAILURE_REASON = {
  FAILED_NORMALLY: "FAILED_NORMALLY",
  FAILED_WITH_BUFFERBLOAT: "FAILED_WITH_BUFFERBLOAT"
};

export const TEST_STATUS = {
  PASS: "PASS",
  FAIL: "FAIL"
};

/*
 How I generate the report: based on the results of the comparisons, I determine whether
 a test will fail in both "normally" and "with bufferbloat" or only one of them:
 * downlink: fails for both
 * uplink: fails for both
 * latency: (check against unloaded, check against loaded) -> specifies
 *          between with-bufferbloat and without-bufferbloat.
 * jitter: same as with latency. If it's less, then it's a pass,
 *          if it's more then it's a fail.
 */

export default class ReportGenerator {
  constructor() {
    this.subscriptions = [];
  }

  start() {
    this.subscribe_for_test_data();
    this.subscribe_for_bandwidths();
    return this;
  }

  subscribe_for_bandwidths() {
    this.downlink_bandwidth = 0;
    this.uplink_bandwidth = 0;
    for (const [source, variable] of [
      [RealtimeDataSource.DOWNLINK_BANDWIDTH, "downlink_bandwidth"],
      [RealtimeDataSource.UPLINK_BANDWIDTH, "uplink_bandwidth"]
    ]) {
      const id = DataDispatcher.subscribe_to(source, (value) => {
        this[variable] = value;
      });
      this.subscriptions.push([source, id]);
    }
  }

  unsubscribe_from_everything() {
    for (const [channel, id] of this.subscriptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }

  subscribe_for_test_data() {
    let id = DataDispatcher.subscribe_to(RealtimeDataSource.AGGREGATED_TEST_DATA, (test_data) => {
      this.latencies = get_latencies_from_test_data(test_data);
      this.make_report();
    });
    this.subscriptions.push([RealtimeDataSource.AGGREGATED_TEST_DATA, id]);
  }

  get_latency_quantile(quantile) {
    return this.latencies.map((values) => {
      return StatisticsUtilities.sort_and_get_quantile(values, quantile);
    });
  }

  get_latency_stds() {
    return this.latencies.map((values) => {
      return StatisticsUtilities.sort_and_get_std(values);
    });
  }

  get_latency_absolute_average_differences() {
    return this.latencies.map((values) => StatisticsUtilities.avg_abs_diff(values));
  }

  get_real_world_impact_and_statistics_and_bufferbloat_impact() {
    const stats = get_important_statistics_as_dict(this.latencies),
    {
      latency_unloaded,
      max_latency,
      latency_95th_unloaded,
      max_latency_95th,
      jitter_unloaded,
      max_jitter
    } = stats;
    const impact_status = {};
    const fails_criterion = (criterion, value) => {
      return (
        (criterion[0] === LARGER_THAN && value < criterion[1]) ||
        (criterion[0] === SMALLER_THAN && value > criterion[1])
      );
    };
    const check_if_category_failed_criteria_then = (category, lookup, then) => {
      console.debug("criteria lookup", lookup);
      for (const criterion_name of Object.keys(lookup)) {
        const criterion = category.criteria[criterion_name];
        if (!criterion) {
          continue
        }
        const value = lookup[criterion_name];
        console.debug("category", category.name, "criterion", criterion_name, criterion, "value", value);
        if (fails_criterion(criterion, value)) {
          if (then) {
            then(criterion_name)
          }
        }
      }
    };

    Categories.forEach(category => {
      let where_it_failed = {};
      let passed = true;
      for (const criteria_lookup of [
        {
          [DOWNLINK]: this.downlink_bandwidth,
          [UPLINK]: this.uplink_bandwidth,
          fail_status: FAILURE_REASON.FAILED_NORMALLY
        },
        {
          [LATENCY]: latency_unloaded,
          [LATENCY_95TH]: latency_95th_unloaded,
          [JITTER]: jitter_unloaded,
          fail_status: FAILURE_REASON.FAILED_NORMALLY
        },
        {
          [LATENCY]: max_latency,
          [LATENCY_95TH]: max_latency_95th,
          [JITTER]: max_jitter,
          fail_status: FAILURE_REASON.FAILED_WITH_BUFFERBLOAT
        }
      ]) {
        check_if_category_failed_criteria_then(category, criteria_lookup, (criterion_name) => {
          passed = false;
          if (criterion_name in where_it_failed &&
            where_it_failed[criterion_name] === FAILURE_REASON.FAILED_NORMALLY) {
            return; // if it normally failed then it'll definitely fail with Bufferbloat
          }
          where_it_failed[criterion_name] = criteria_lookup.fail_status;
        });
      }
      impact_status[category.name] = {
        where_it_failed: where_it_failed,
        passed: passed
      }
    });
    // starting from 1.0.4 we store this as well since it directly corresponds to our tables
    const bufferbloat_impact = {};
    Object.keys(impact_status).forEach(category => {
      const impact = impact_status[category];
      const [passed, failed_with_bufferbloat] = check_passed(impact, NEW_TABLE_VERSION);
      const entry = {};
      if (passed) {
        entry.normally = TEST_STATUS.PASS;
        entry.with_bufferbloat = TEST_STATUS.PASS;
      } else if (failed_with_bufferbloat) {
        entry.normally = TEST_STATUS.PASS;
        entry.with_bufferbloat = TEST_STATUS.FAIL;
      } else {
        entry.normally = TEST_STATUS.FAIL;
        entry.with_bufferbloat = TEST_STATUS.FAIL;
      }
      bufferbloat_impact[category] = entry;
    });
    return [impact_status, stats, bufferbloat_impact];
  }

  make_report() {
    const [impact, statistics, bufferbloat_impact] = this.get_real_world_impact_and_statistics_and_bufferbloat_impact();
    const report = {
      bufferbloat_grade: get_bufferbloat_info(this.latencies).grade,
      real_world_impact: impact,
      statistics: statistics,
      bufferbloat_impact: bufferbloat_impact // 1.0.4
    };
    console.log("generated report", report);
    DataDispatcher.broadcast_to(CalculatedDataSource.REPORT, report);
    this.unsubscribe_from_everything();
  }
}



