import React, {Component} from 'react';
import TestWalkthroughComponent from "./test_walkthrough_component";
import TestReportComponent from "./test_report_component";


export default class LeftColumnComponent extends Component {
  render() {
    return (
      <>
        <TestWalkthroughComponent className={"medium-and-larger-screen"}/>
        <TestReportComponent/>
      </>
    )
  }
}
