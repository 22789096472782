import React, {Component} from 'react';
import "./test_detail_view_component.scss";
import {TestContext} from "../stages/context";
import BeforeYouStartComponent from "./before_you_start_component";
import {Stages} from "../stages/stages";
import LatencyBanner from "./latency_banner";
import SpeedBanner from "./speed_banner";
import "./banner.scss"


class TestDetailViewComponent extends Component {
  render() {
    const wrap_in = (component) => (
      <div className="test-detail-view">
        {component}
      </div>
    );
    if (this.context.current_stage === Stages.IDLE) {
      return wrap_in(<BeforeYouStartComponent/>);
    }
    return (
      wrap_in(
        <>
          <LatencyBanner/>
          <SpeedBanner/>
        </>
      )
    )
  }
}

TestDetailViewComponent.contextType = TestContext;

export default TestDetailViewComponent;

