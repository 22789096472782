import React, {Component} from 'react';
import {TestContext} from "../stages/context";
import {Stages} from "../stages/stages";
import {AppEvent, LoadedDataSource, RealtimeDataSource} from "../test_runner/data_source";
import {AppConfiguration} from "../app_configuration";
import {DataDispatcher} from "../test_runner/data_dispatcher";
import LatencyStageReport from "./latency_stage_report";
import "./statistics_view.scss";


class StatisticsView extends Component {
  constructor(props) {
    super(props);
    this.title_lookup = {
      [Stages.UNLOADED]: "Unloaded",
      [Stages.DOWNLINK]: "↓ Active",
      [Stages.UPLINK]: "↑ Active",
    };
    this.source_lookup = {
      [Stages.UNLOADED]: RealtimeDataSource.UNLOADED_LATENCY,
      [Stages.DOWNLINK]: RealtimeDataSource.DOWNLINK_LATENCY,
      [Stages.UPLINK]: RealtimeDataSource.UPLINK_LATENCY
    };
    this.state = {
      box_plot_scale: null
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    let id = 0;
    for (const stage of Object.keys(this.source_lookup)) {
      id = DataDispatcher.subscribe_to(this.source_lookup[stage], this.adjust_scale.bind(this));
      this.subscriptions.push([this.source_lookup[stage], id]);
    }
    id = DataDispatcher.subscribe_to(LoadedDataSource.BOX_PLOT_SCALE, this.load_scale.bind(this));
    this.subscriptions.push([LoadedDataSource.BOX_PLOT_SCALE, id]);
    id = DataDispatcher.subscribe_to(AppEvent.RESTARTING_TEST, () => {
      this.setState({
        box_plot_scale: null
      })
    });
    this.subscriptions.push([AppEvent.RESTARTING_TEST, id]);
  }

  componentWillUnmount() {
    for (let [channel, id] of this.subscriptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }

  load_scale(scale) {
    this.setState({
      box_plot_scale: scale
    });
  }

  adjust_scale([latency, timestamp]) {
    let min = 0, max = 0;
    if (!this.state.box_plot_scale) {
      min = latency - 0.1;
      max = latency + 0.1;
    } else {
      [min, max] = this.state.box_plot_scale;
    }
    if (latency < min) {
      min = latency;
    }
    if (latency > max) {
      max = latency;
    }
    this.setState({
      box_plot_scale: [min, max]
    });
  }

  render() {
    if (this.context.current_stage === Stages.IDLE) {
      return null;
    }
    return (
      <div className={"statistics-view"}>
        { Object.keys(this.title_lookup)
          .filter((stage, index) => index < this.context.current_stage_idx)
          .map((stage, index) => (
            <LatencyStageReport
              key={index}
              spacer_before={index !== 0}
              box_plot_scale={this.state.box_plot_scale}
              title={this.title_lookup[stage]}
              stage={stage}
            />
          ))
        }
      </div>
    )
  }
}

StatisticsView.contextType = TestContext;

export default StatisticsView;
