export default class Pipe {
  constructor() {
    this.data = [];
    this.next_pipe = null;
  }

  feed(value) {
    this.data.push(value);
    this.process(value, this.data);
  }

  process(new_value, data) {
    this.out(new_value);
  }

  out(value) {
    if (!this.next_pipe) {
      return;
    }
    if (this.next_pipe instanceof Pipe) {
      this.next_pipe.feed(value);
    } else {
      this.next_pipe(value);
    }
  }

  pipe_to(other_pipe) {
    this.next_pipe = other_pipe;
    return this.next_pipe;
  }
}

