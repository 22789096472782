import {
  BrowserRouter as Router,
  withRouter
} from "react-router-dom";

import React, { Component } from "react";
import { AppConfiguration } from "../app_configuration";
import BufferBloatTestComponent from "../components/bufferbloat_test_component";
import "./main_page_styles.scss";
import { Collapse, Modal, Button, Alert } from "react-bootstrap";
import { FAQs } from "./main_page_faqs.js";
import TimeUtility from "../utilities/time_utility";
import { get_json_ld_script } from "./faq_google.js";
import { DataDispatcher } from "../test_runner/data_dispatcher.js";
import { AppEvent } from "../test_runner/data_source.js";

class QueryParamsExtractor extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const test_id = query.get(AppConfiguration.TEST_ID_QUERY);
    const running_new_test = (test_id || "").length === 0;
    performance.setResourceTimingBufferSize(AppConfiguration.RESOURCE_TIMING_BUFFER_SIZE);
    this.state = {
      test_result: null,
      running_new_test: running_new_test,
      test_id: test_id,
      faqs: FAQs.map((faq) => {
        return {
          ...faq,
          show: false
        }
      })
    };
  }

  async scroll_to_question_from_location(wait = 0) {
    const { location } = this.props;
    const hash = location.hash;
    const hash_index = hash.split('-').pop();
    if (!hash_index) {
      return;
    }
    return this.scroll_to_question(parseInt(
      hash_index
    ), wait)
  }

  componentDidUpdate(next_props) {
    if (next_props.location !== this.props.location) {
      if (this.scrolling) {
        return;
      }
      this.scrolling = true;
      this.scroll_to_question_from_location().then(() => {
        this.scrolling = false;
      });
    }
  }

  async scroll_to_question(index, wait = 0) {
    const faqs = [...this.state.faqs];
    faqs[index].show = true;
    await this.setState({
      ...this.state,
      faqs: faqs
    });
    console.log(this.state.faqs);
    await TimeUtility.sleep(wait);
    window.location = `#question-${index}`;
  }

  componentWillUnmount() {
    DataDispatcher.unsubscribe_from(this.error_subscription);
  }

  componentDidMount() {
    this.error_subscription = DataDispatcher.subscribe_to(AppEvent.ERROR, (error) => {
      this.setState({
        error: error
      });
    });

    window.addEventListener('offline', () => {
      this.setState({
        error: {
          title: "Lost Internet Connection",
          description: "Please check your internet connection and refresh this page."
        }
      });
    });
    this.scroll_to_question_from_location().then(() => { });
  }

  toggle(qa) {
    qa.show = !qa.show;
    this.setState({
      faqs: this.state.faqs
    });
  }

  render() {
    return (
      <div className="bufferbloat-application">
        <Modal show={this.state.error}>
          <Alert bsStyle="danger" style={{ marginBottom: 0 }}>
            <h4>{this.state.title}</h4>
            <p>
              {this.state.description}
            </p>
            <Button bsStyle="danger" onClick={() => { this.setState({ error: null }) }}>Close</Button>
          </Alert>
        </Modal>
        <BufferBloatTestComponent
          running_new_test={this.state.running_new_test}
          test_id={this.state.test_id}
        />

        <div className="container main-page main-page-affiliate">
          <h2>Best Routers For Mitigating Bufferbloat</h2>
          <p>Please note that these are affiliate links. We make a small referral fee from Amazon for each purchase, which helps us cover hosting costs.</p>

          <div className="main-page-products">
            <div className="main-page-product">
              <div className="img-container">
                <a href="https://amzn.to/3BR6Mj2" target="_blank" rel="nofollow">
                  <img src={`${AppConfiguration.STATIC_RESOURCES_PATH}/images/Amazon_eero_Pro_mesh_WiFi_router.svg`} alt="Image of Amazon eero Pro 6 mesh WiFi router" />
                </a>
              </div>
              <a href="https://amzn.to/3BR6Mj2" class="header-link" target="_blank" rel="nofollow">
                <h4>Amazon eero Pro 6 mesh WiFi router</h4>
              </a>
              <p>Built-in SQM supports connections up to 1 Gbps. Turn on using “Optimize for Conferencing and Gaming” option under Eero Labs.</p>
              <a href="https://amzn.to/3BR6Mj2" target="_blank" className="view-amazon-button" rel="nofollow"><img src={`${AppConfiguration.STATIC_RESOURCES_PATH}/images/amazon.png`} alt="Amazon Logo" /><span>View on Amazon</span></a>
            </div>

            <div className="main-page-product">
              <div className="img-container">
                <a href="https://amzn.to/3vhZ14D" target="_blank" rel="nofollow">
                  <img src={`${AppConfiguration.STATIC_RESOURCES_PATH}/images/Netgear_nighthawk.svg`} alt="Image of NETGEAR Nighthawk Pro Gaming 6-Stream WiFi 6 Router (XR1000)" />
                </a>
              </div>
              <a href="https://amzn.to/3vhZ14D" class="header-link" target="_blank" rel="nofollow">
                <h4>NETGEAR Nighthawk Pro Gaming 6-Stream WiFi 6 Router (XR1000)</h4>
              </a>
              <p>Uses DumaOS software that includes a number of features, including built-in SQM for controlling bufferbloat.</p>
              <a href="https://amzn.to/3vhZ14D" target="_blank" className="view-amazon-button" rel="nofollow"><img src={`${AppConfiguration.STATIC_RESOURCES_PATH}/images/amazon.png`} alt="Amazon Logo" /><span>View on Amazon</span></a>
            </div>

            <div className="main-page-product">
              <div className="img-container">
                <a href="https://amzn.to/3sm882l" target="_blank" rel="nofollow">
                  <img src={`${AppConfiguration.STATIC_RESOURCES_PATH}/images/IQrouter.svg`} alt="Image of IQRV3 Self-Optimizing Router with Dual Band WiFi" />
                </a>
              </div>
              <a href="https://amzn.to/3sm882l" class="header-link" target="_blank" rel="nofollow">
                <h4>IQrouter – IQRV3 Self-Optimizing Router with Dual Band WiFi</h4>
              </a>
              <p>A router that’s designed from the ground up to minimize bufferbloat. Supports connections up to 300 Mbps.</p>
              <a href="https://amzn.to/3sm882l" target="_blank" className="view-amazon-button" rel="nofollow"><img src={`${AppConfiguration.STATIC_RESOURCES_PATH}/images/amazon.png`} alt="Amazon Logo" /><span>View on Amazon</span></a>
            </div>

            <div className="main-page-product">
              <div className="img-container">
                <a href="https://amzn.to/3M5UxnG" target="_blank" rel="nofollow">
                  <img src={`${AppConfiguration.STATIC_RESOURCES_PATH}/images/Ubiquiti_EdgeRouter_4.svg`} alt="Image of Ubiquiti EdgeRouter 4" />
                </a>
              </div>
              <a href="https://amzn.to/3M5UxnG" class="header-link" target="_blank" rel="nofollow">
                <h4>Ubiquiti EdgeRouter 4</h4>
              </a>
              <p>With SQM enabled an EdgeRouter 4 can support connections up to around 350 Mbps. Interface is intended for more advanced users.</p>
              <a href="https://amzn.to/3M5UxnG" target="_blank" className="view-amazon-button" rel="nofollow"><img src={`${AppConfiguration.STATIC_RESOURCES_PATH}/images/amazon.png`} alt="Amazon Logo" /><span>View on Amazon</span></a>
            </div>
          </div>
        </div>
        <div className="container main-page-description main-page">
          <div className={`FAQContainer`}>
            <h2>Frequently Asked Questions</h2>
            {this.state.faqs.map((qa, i) => {
              return (<div
                key={i}
                className={"FAQ"}
              >
                <div className={"question"} id={`question-${i}`}>
                  <p onClick={() => { this.toggle(qa) }}>
                    <span>{qa.question}</span>
                    <img className={qa.show ? "shown" : "not-shown"} src={AppConfiguration.STATIC_RESOURCES_PATH + "/images/arrow-down.svg"} alt="Click to reveal answer." />
                  </p>
                </div>
                <Collapse in={qa.show}>
                  <div className={"FAQAnswer"} >
                    {qa.answer}
                  </div>
                </Collapse>
              </div>)
            })}
          </div>
        </div>
      </div>
    )
  }
}

const QueryParamsExtractorWithRouter = withRouter(QueryParamsExtractor);

export default class MainPage extends Component {
  render() {
    return (
      <>
        {get_json_ld_script()}
        <Router>
          <QueryParamsExtractorWithRouter />
        </Router>
      </>
    )
  }
}


