import React, {Component} from 'react';
import {TestContext} from "../stages/context";
import "./test_title.scss";


export default class TestTitle extends Component {
  constructor(props) {
    super(props);
    this.test_title_lookup_medium_or_larger = [
      "Bufferbloat and Internet Speed Test",
      "Measuring Unloaded Latency",
      "Download Speed & Latency Test",
      "Upload Speed & Latency Test",
      "Your Results"
    ];
    this.test_title_small_screen = [
      "Bufferbloat & Speed Test",
      "Measuring Unloaded Latency",
      "Download + Latency Test",
      "Upload + Latency Test",
      "Your Results"
    ];
  }

  get_prefix_medium_or_larger() {
    const curr_idx = this.context.current_stage_idx;
    if (curr_idx > 0 && curr_idx < 4) {
      return `Step ${curr_idx} - `;
    }
    return "";
  }

  get_prefix_small_screen() {
    const curr_idx = this.context.current_stage_idx;
    if (curr_idx === 0) {
      return "";
    }
    if (curr_idx < 4) {
      return `Step ${curr_idx} of 3`;
    }
    return "Test Complete!";
  }

  is_prefixed() {
    if (this.get_prefix_small_screen().length) {
      return "is-prefixed";
    }
    return "";
  }

  render() {
    const prefix_sm = this.get_prefix_small_screen();
    return (
      <div className={"test-title"}>
        <h1 className={`test-title-small-screen ${this.is_prefixed()}`}>
          <span className={"prefix"}>
            {prefix_sm}
          </span>
          {prefix_sm && (<br/>)}
          {this.test_title_small_screen[this.context.current_stage_idx]}
        </h1>
        <h1 className={"test-title-medium-or-larger"}>
          <span className={"prefix"}>
            {this.get_prefix_medium_or_larger()}
          </span>
          {this.test_title_lookup_medium_or_larger[this.context.current_stage_idx]}
        </h1>
      </div>
    )
  }
}


TestTitle.contextType = TestContext;
