import { saveAs } from 'file-saver';
import TimeUtility from "./time_utility";

const CSV_FILE_TYPE = "text/plain;charset=utf-8";

export default class CSVGenerator {
  static async generate_csv_from_url(url, filename) {
    const res = await fetch(url);
    const content = await res.text();
    const file = new File([content], filename, { type:CSV_FILE_TYPE });
    saveAs(file);
  }

  static generate_csv_from_array(data, filename, headers) {
    const DELIMITER = ",",
      DEFAULT_FILE=`data-${TimeUtility.get_current_date()}.csv`,
      FILE_TYPE = "text/plain;charset=utf-8";
    filename = filename || DEFAULT_FILE;
    let csv_content = "";
    if (headers) {
      csv_content += headers.join(DELIMITER) + "\n";
    }
    csv_content += data.reduce((rows, row) => {
      if (Array.isArray(rows)) {
        rows = rows.join(DELIMITER) + "\n";
      }
      rows += row.join(DELIMITER) + "\n";
      return rows;
    });
    let file = new File(
      [csv_content],
      filename,
      {type: FILE_TYPE});
    saveAs(file);
  }
}


