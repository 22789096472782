import React, {Component} from 'react';
import "./latency_banner.scss";
import {LoadedDataSource, RealtimeDataSource} from "../test_runner/data_source";
import {TestContext} from "../stages/context";
import {DataDispatcher} from "../test_runner/data_dispatcher";
import MedianLazyBuffer from "../lazy_buffers/median_lazy_buffer";
import {Stages} from "../stages/stages";
import {is_median} from "../version_specific/report_generation.js";
import AveragingLazyBuffer from "../lazy_buffers/averaging_lazy_buffer.js";


export default class LatencyBanner extends Component {
  TO_FIXED_DIGITS = 0 ;

  constructor(props) {
    super(props);
    this.state = {
      stages: [
        {
          title: "Unloaded",
          realtime_data_source: RealtimeDataSource.UNLOADED_LATENCY,
          loaded_data_source: LoadedDataSource.LOADED_UNLOADED_LATENCIES
        },
        {
          title: "Download Active",
          realtime_data_source: RealtimeDataSource.DOWNLINK_LATENCY,
          loaded_data_source: LoadedDataSource.LOADED_DOWNLINK_LATENCIES
        },
        {
          title: "Upload Active",
          realtime_data_source: RealtimeDataSource.UPLINK_LATENCY,
          loaded_data_source: LoadedDataSource.LOADED_UPLINK_LATENCIES
        }
      ]
    };
    this.title = "latency";
    this.subscriptions = [];
  }

  componentDidMount() {
    const stages = this.state.stages;
    for (const idx in stages) {
      const stage = stages[idx];
      stage.value = null;
      const buffer = is_median() ? new MedianLazyBuffer() : new AveragingLazyBuffer();
      buffer.register_subscriber((value) => {
        stage.value = value;
        this.setState({stages});
      });
      stage.buffer = buffer;
      let sub_id = DataDispatcher.subscribe_to(stage.realtime_data_source, ([latency, ]) => {
        stage.buffer.update(latency);
      });
      this.subscriptions.push([stage.realtime_data_source, sub_id]);
      sub_id = DataDispatcher.subscribe_to(stage.loaded_data_source, (data) => {
        stage.buffer.update_chunk(data.map(d => d.value));
      });
      this.subscriptions.push([stage.loaded_data_source, sub_id]);
    }
    this.setState({stages});
  }

  componentWillUnmount() {
    for (let [channel, id] of this.subscriptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }

  render() {
    const format_value = (value) => {
      if (!value) {
        return null;
      }
      return (<>
        {value}
        <span> ms</span>
      </>);
    };

    const callout = (stage, value, index) => {
      const text = format_value(value);
      let is_big = "";
      if (value.length >= 4) {
        is_big = "is-big";
      }
      return (
        <div className={"callout"} key={index}>
          <h4>{stage.title}</h4>
          <p className={is_big}>
            {text}
          </p>
        </div>
      );
    };
    let stages = this.state.stages;
    let first_stage = stages[0];

    const format = (value, prefix, index) => {
      if (this.context.current_stage_idx - 1 < index) {
        return "";
      }
      if (!value) {
        return `${prefix}0`;
      }
      let fixed = value.toFixed(this.TO_FIXED_DIGITS);
      if (value <= 0 || fixed === "0") {
        return `${prefix}0`;
      }
      return `${prefix}${fixed}`;
    };

    return (
      <div className={`latency-banner banner`}>
        <h3>{this.title.toUpperCase()}</h3>
        <div className="callout-container">
          { (this.context.current_stage !== Stages.IDLE) &&
            (callout(first_stage, format(first_stage.value, "", 0), 0))
          }
          {
            stages
              .slice(1)
              .map((stage, index) => {
                return callout(
                  stage,
                  format(stage.value - first_stage.value, "+", index + 1),
                  index + 1
                )
              })
          }
        </div>
      </div>
    )
  }
}

LatencyBanner.contextType = TestContext;
