import URLBuilder from "./url_builder.js";
import LatencyUtililties from "./latency_utilities.js";
import {AppConfiguration} from "../app_configuration.js";

export default class CfWorkersLatencyCollector {
  static async warmup_all_cflare_workers_connections() {
    const promises = Array(AppConfiguration.NUM_LATENCY_STREAMS).fill(0).map((_, i) => {
      return CfWorkersLatencyCollector.get_cloudflare_diff(i);
    });
    await Promise.all(promises);
  }

  static async get_cloudflare_diff(index) {
    const url = URLBuilder.get_cf_workers_url(index);
    return await LatencyUtililties.measure_diff_minus_server_timing(url);
  }

  static async get_cloudflare_rtt(index) {
    const url = URLBuilder.get_cf_workers_url(index);
    return await LatencyUtililties.measure_url_rtt(url);
  }
}
