import React, {Component} from 'react';
import Card from "./card";
import UnderlinedHeader from "./underlined_header";
import "./before_you_start.scss";


export default class BeforeYouStartComponent extends Component {
  render() {
    return (
      <Card className={"before-you-start"}>
        <UnderlinedHeader>
          Before You Start
        </UnderlinedHeader>
        <p className={"numbered-point"}>
          1. Disable any bandwidth-heavy tasks on your network
        </p>
        <p className={"hide-if-small"}>
          It’s important that your Internet connection’s upload and download connections aren’t saturated already.
        </p>
        <p class="hidden-xs hidden-sm hidden-md">
          Make sure no devices are currently uploading or downloading large files from the Internet (e.g. backup/sync
          tasks by Google Drive, Dropbox, etc).
        </p>
        <p className={"numbered-point"}>
          2. Make sure your connection to your router is at least as fast as your Internet connection itself
        </p>
        <p>
          For example, if you have a gigabit fiber internet connection, make sure you’re connected over a gigabit ethernet cable, not over WiFi.
        </p>
        <p className={"hide-if-small"}>
          Our speed test won’t be able to “saturate” your Internet connection to test for bufferbloat if you’re limited by your WiFi connection.
        </p>
      </Card>
    )
  }
}