import * as d3 from 'd3';
import * as d3Regression from 'd3-regression';


export default class StatisticsUtilities {
  static avg(data) {
    return data.reduce((a, b) => a + b)/data.length;
  }

  static sort(data) {
    return [...data].sort(d3.ascending);
  }

  static sort_and_get_median(data) {
    return this.sort_and_get_quantile(data, 0.5);
  }

  static sort_and_get_quantile(data, quantile) {
    return d3.quantile(this.sort(data),quantile);
  }

  static sort_and_get_std(data) {
    return d3.deviation(this.sort(data));
  }

  static calculate_important_values(sorted_data) {
    const avg = (vals) => vals.reduce((a, b) => a + b, 0)/(vals.length || 1),
      q1 = d3.quantile(sorted_data,.25),
      q3 = d3.quantile(sorted_data,.75),
      median = d3.quantile(sorted_data,.5),
      min = sorted_data[0],
      max = sorted_data[sorted_data.length - 1],
      jitter = this.avg_abs_diff(sorted_data) || 0,
      quantile_95th = d3.quantile(sorted_data, 0.95),
      mean = avg(sorted_data),
      less_than_95th = sorted_data.filter(d => d < quantile_95th),
      mean_95th = avg(less_than_95th);
    return {
      q1: q1,
      q3: q3,
      median: median,
      mean: mean,
      mean_95th: mean_95th,
      min: min,
      max: max,
      jitter: jitter,
      quantile_95th: quantile_95th,
    };
  }

  static avg_abs_diff(values) {
    if (values.length === 0) {
      console.warn("collected 0 latency values");
      return 0;
    }
    const take_avg = (vals) => (vals.reduce((a, b) => a + b) / vals.length);
    return take_avg(values.map(a => Math.abs(a - take_avg(values))));
  }

  static get_linear_regression(data) {
    let linearRegression = d3Regression.regressionLinear()
      .x(d => d.x)
      .y(d => d.y);
    return linearRegression(data);
  }
}
