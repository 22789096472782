import Pipe from "./pipe";


export default class SkipIfSmallerThanPipe extends Pipe {
  constructor(min_size) {
    super();
    this.min_size = min_size;
  }

  process(value, values) {
    const n = this.min_size;
    if (values.length < n) {
      return;
    }
    this.process_filtered(value, values);
  }

  process_filtered(value, values) {
    this.out(value);
  }
}