import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {DataDispatcher} from "../test_runner/data_dispatcher";
import * as d3 from 'd3';
import StatisticsUtilities from "../utilities/statistics_utilities";
import {Stages} from "../stages/stages";
import {LoadedDataSource, RealtimeDataSource} from "../test_runner/data_source";


export default class LatencyStatsComponent extends Component {
  constructor(props) {
    super(props);
    this.data = [];
    this.state = {
      min: 0,
      max: 0,
      q1: 0,
      q3: 0,
      median: 0,
      quantile_95th: 0
    };
    this.realtime_source_lookup = {
      [Stages.UNLOADED]: RealtimeDataSource.UNLOADED_LATENCY,
      [Stages.DOWNLINK]: RealtimeDataSource.DOWNLINK_LATENCY,
      [Stages.UPLINK]: RealtimeDataSource.UPLINK_LATENCY
    };
    this.loaded_source_lookup = {
      [Stages.UNLOADED]: LoadedDataSource.LOADED_UNLOADED_LATENCIES,
      [Stages.DOWNLINK]: LoadedDataSource.LOADED_DOWNLINK_LATENCIES,
      [Stages.UPLINK]: LoadedDataSource.LOADED_UPLINK_LATENCIES
    };
    this.subscriptions = [];
  }

  sort_and_update() {
    this.data.sort(d3.ascending);
    let stats = StatisticsUtilities
      .calculate_important_values(this.data);
    for (const s in stats) {
      stats[s] = parseFloat(stats[s].toFixed(1));
    }
    this.setState(stats);
  }

  componentDidMount() {
    const [rt_channel, loaded_channel] = [
      this.realtime_source_lookup[this.props.stage],
      this.loaded_source_lookup[this.props.stage]
    ];
    let id = DataDispatcher.subscribe_to(rt_channel,([latency, ]) => {
      this.data.push(latency);
      this.sort_and_update();
    });
    this.subscriptions.push([rt_channel, id]);
    id = DataDispatcher.subscribe_to(loaded_channel, (latency_data) => {
      this.data = latency_data.map(datum => datum.value);
      this.sort_and_update();
    });
    this.subscriptions.push([loaded_channel, id]);
  }

  componentWillUnmount() {
    for (let [channel, id] of this.subscriptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }

  render() {
    const format = {
      min: "Min",
      median: "Median",
      max: "Max",
      mean: "Mean",
      q1: "25th %ile",
      q3: "75th %ile",
      quantile_95th: "95th %ile",
      jitter: "Jitter"
    };
    return (
      <>
        <Row style={{whiteSpace: 'nowrap'}}>
          <Col sm={6}>
            {["min", "median", "max", "mean"].map((s, i) => (<span key={i}>
              {format[s]}: {this.state[s]}&nbsp;ms<br/>
            </span>))}
          </Col>
          <Col sm={6}>
            {["q1", "q3", "quantile_95th", "jitter"].map((s, i) => (<span key={i}>
              {format[s]}: {this.state[s]}&nbsp;ms<br/>
            </span>))}
          </Col>
        </Row>
      </>
    )
  }
}
