import React, {Component} from 'react';
import "./underlined_header.scss"

export default class UnderlinedHeader extends Component {
  render() {
    return (
      <>
        <div className={"underlined-header " + this.props.className}>
          <h3>{this.props.children}</h3>
          <div className="underline"/>
        </div>
      </>
    )
  }
}

