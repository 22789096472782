import {RuntimeEnvironment} from "./runtime_environment";

export default class TimeUtility {
  static get_current_date() {
    let d = new Date();
    return `${d.getFullYear()}-${d.getMonth()}-${d.getDay()}-${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
  };

  static async sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    })
  }

  static get_perf_entry(resource_name) {
    let entries = performance.getEntriesByName(resource_name);
    return entries.slice(-1).pop();
  }

  static async time_this_runnable(async_runnable) {
    const start = Date.now();
    const result = await async_runnable();
    const duration = Date.now() - start;
    return {start, duration, result, measurement: duration};
  }

  static get_upload_time_for(resource_name) {
    let perf = TimeUtility.get_perf_entry(resource_name);
    return perf.responseStart - perf.requestStart
  }

  static async time_this_resources_upload(resource_name, async_runnable) {
    await async_runnable();
    return this.get_upload_time_for(resource_name)
  }

  static async time_this_resources_download(resource_name, async_runnable) {
    const begin = Date.now();
    await async_runnable();
    const duration = Date.now() - begin;
    const ttfb = this.get_ttfb_for(resource_name);
    const perf = TimeUtility.get_perf_entry(resource_name);
    console.debug("for resource: " + resource_name);
    console.debug("duration: " + duration);
    console.debug("ttfb: " + ttfb);
    return duration - this.get_ttfb_for(resource_name);
  }

  static get_download_time_for(resource_name) {
    let perf = TimeUtility.get_perf_entry(resource_name);
    return perf.responseEnd - perf.responseStart
  }

  static get_ttfb_for(resource_name) {
    let perf = TimeUtility.get_perf_entry(resource_name);
    let ttfb = Infinity;
    console.debug("perf", perf);
    if (!perf) {
      console.log("ERROR: no performance entry found found for this resource", resource_name, perf);
      return {ttfb, perf};
    }
    ttfb = perf.responseStart - perf.requestStart;
    const download_time = perf.responseEnd - perf.responseStart;
    console.debug("ttfb", ttfb);
    return {ttfb, perf, download_time};
  }
}
