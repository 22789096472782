import React, {Component} from 'react';
import "./connection_result_shield_banner.scss"
import {DataDispatcher} from "../test_runner/data_dispatcher";
import {CalculatedDataSource} from "../test_runner/data_source";
import {AppConfiguration} from "../app_configuration";


export default class ConnectionResultShieldBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      good_for: [],
      not_good_for: []
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    let id = DataDispatcher.subscribe_to(CalculatedDataSource.REPORT, (report) => {
      this.setState({...report});
    });
    this.subscriptions.push([CalculatedDataSource.REPORT, id])
  }

  componentWillUnmount() {
    for (let [channel, id] of this.subscriptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }

  render() {
    const is_good_view = (good, what) => {
      if (!what || !what.length) {
        return null;
      }
      return (
        <div className={`${good ? "": "not-"}good-for`}>
          <span><img alt={"quality indicator"}
                     src={`${AppConfiguration.STATIC_RESOURCES_PATH}/images/${good ? "shield": "bad"}.svg`}/></span>
          <p>
            <strong>{`${good ? "": "Not "}Good Enough For:`}</strong> {what.join(", ")}
          </p>
        </div>
      )
    };
    const good_for_view = (good_for) => is_good_view(true, good_for);
    const not_good_for_view = (not_good_for) => is_good_view(false, not_good_for);
    const [good_for, not_good_for] = [this.state.good_for, this.state.not_good_for];
    return (
      <div className="your-connection-is-good-for">
        <div className="header">
          <h4>YOUR CONNECTION</h4>
        </div>
        {good_for_view(good_for)}
        {not_good_for_view(not_good_for)}
        <div className="read-more">
          <span>Read More</span>
        </div>
      </div>
    )
  }
}
