import React, {Component} from 'react';
import BoxPlot from "./box_plot";

export default class LatencyBoxPlot extends Component {
  render() {
    const format_time = (time) => {
      const d = new Date(time);
      return `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}`;
    };

    // eslint-disable-next-line no-template-curly-in-string
    const tooltip_html = "latency: ${d.value.toFixed(2)}ms</br>time: ${d.timestamp}";

    return (
      <BoxPlot
        {...this.props}
        data={this.props.data.map(v => {
          return {
            value: v.value, timestamp: format_time(v.timestamp)
        }})}
        tooltip_html={tooltip_html}
      />
    );
  }

}
