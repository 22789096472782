const geoip2 = window.geoip2;

export const get_geoip_info = async () => {
  return new Promise(async resolve => {
    if (!geoip2) {
      resolve({ error: "couldn't find geoip info" });
      return;
    }
    geoip2.city((geoip_information) => {
      resolve({
        ip: geoip_information.traits.ip_address.split(".").slice(0,-1).join("."),
        city: geoip_information.city.names.en,
        country: geoip_information.country.names.en,
        isp: geoip_information.traits.isp
      });
    }, (err) => {
      console.warn("failed to retrieve maxmind info", err);
      resolve({})
    });
  });
};

export const find_ip_ipstack = async () => {
  const api_key = process.env.REACT_APP_IPSTACK_API_KEY;
  if (!api_key) {
    console.warn("Missing API key for IP lookup");
    return "";
  }
  const url = `https://api.ipstack.com/check?access_key=${api_key}`;
  try {
    const res = await fetch(url);
    const ip_info = await res.json();
    return ip_info.ip.split(".").slice(0, -1).join(".");
  } catch (e) {
    console.warn("couldn't get ip", e);
    return "";
  }
};


