import SkipIfSmallerThanPipe from "./skip_if_smaller_than_pipe";

export default class AveragingBandwidthCalculationPipe extends SkipIfSmallerThanPipe {
  constructor(min_size) {
    super(min_size);
    this.average_window_size = 0; // averages over everything
  }

  process_filtered(value, bytes) {
    const n = this.average_window_size;
    const [this_time, this_data] = bytes.slice(-1).pop();
    const [last_time, last_data] = bytes.slice(-n, -n+1).pop();
    const bw = ((this_data - last_data )/(this_time - last_time) * 8 * 1e3);
    this.out(bw);
  }
}
