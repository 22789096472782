import React, {Component} from 'react';
import UnderlinedHeader from "./underlined_header";
import Card from "./card";
import "./test_walkthrough_component.scss"
import {TestContext} from "../stages/context";
import {Stages} from "../stages/stages";


export default class TestWalkthroughComponent extends Component {
  render() {
    if (this.context.current_stage === Stages.DONE) {
      return null;
    }

    return (
      <Card className={`test-walkthrough ${this.props.className}`}>
        <div class="intro-text">
          <p>
            Do your video or audio calls sometimes stutter? Does your web browsing slow down? Do video games lag?
          </p>
          <p><strong>If so, bufferbloat may be to blame.</strong></p>
        </div>
        <UnderlinedHeader>
          What Is Bufferbloat?
        </UnderlinedHeader>
        <p>
          Bufferbloat is a software issue with networking equipment that causes spikes in your Internet connection’s latency when a device on the network uploads or downloads files.
        </p>
        <UnderlinedHeader className={"second-header"}>
          How This Test Works
        </UnderlinedHeader>
        <p className="explanation">
          <span className={`${this.context.current_stage === Stages.UNLOADED ? "highlighted" : ""}`}>
            First we test the latency of your internet connection.
          </span>
          {' '}
          <span className={`${this.context.current_stage === Stages.DOWNLINK ? "highlighted" : ""}`}>
            Then we compare the results to a latency test performed while running a download speed test
          </span>
          {' '}
          <span className={`${this.context.current_stage === Stages.UPLINK ? "highlighted" : ""}`}>
            and an upload speed test.
          </span>
        </p>
        <p class="hidden-md hidden-sm">
          If the latency increases while the upload or download tests are happening, your router/networking equipment suffers from bufferbloat.
        </p>
      </Card>
    )
  }
}


TestWalkthroughComponent.contextType = TestContext;
