import StatisticsUtilities from "../utilities/statistics_utilities.js";
import {BufferbloatGradings} from "./report_generation_criteria.js";
import {is_median} from "../version_specific/report_generation.js";

export function get_latencies_from_test_data(test_data) {
  return Object.keys(test_data.stages).map(stage => {
    return test_data.stages[stage].latencies.map(measurement => measurement[0]);
  });
}

export function get_latency_medians(latencies) {
  return latencies.map((values) => {
    return StatisticsUtilities.sort_and_get_median(values);
  });
}

export function get_latency_means(latencies) {
  return latencies.map((values) => {
    return values.reduce((a, b) => a + b, 0)/(values.length || 1);
  });
}

export function get_bufferbloat_info(latencies) {
  const values = is_median() ? get_latency_medians(latencies) : get_latency_means(latencies);
  const downlink_bufferbloat = values[1] - values[0];
  const uplink_bufferbloat = values[2] - values[0];
  const max_bufferbloat = Math.max(downlink_bufferbloat, uplink_bufferbloat);
  for (const grading of BufferbloatGradings) {
    if (max_bufferbloat < grading.until) {
      return {
        downlink_bufferbloat,
        uplink_bufferbloat,
        max_bufferbloat,
        grade: grading.grade
      };
    }
  }
}

