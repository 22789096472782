import StatisticsUtilities from "../utilities/statistics_utilities.js";

const get_median = StatisticsUtilities.sort_and_get_median.bind(StatisticsUtilities);

export async function do_this_n_times_concurrently(runnable, n) {
  const promises = Array(n).fill(0).map(_ => {
    return new Promise(resolve => {
      runnable().then(resolve);
    });
  });
  return Promise.all(promises);
}

export async function collect_n_measurements_concurrently(runnable, n) {
  const measurements = await do_this_n_times_concurrently(runnable, n);
  return measurements.filter(d => d !== null).map(d => d.measurement);
}

export async function collect_n_measurements_synchronously(runnable, n) {
  const measurements = [];
  for (let i = 0; i < n; i++) {
    const meas = await runnable();
    measurements.push(meas);
  }
  return measurements.map(d => d[0]);
}

export async function median_n_measurements_concurrently(runnable, n) {
  const measurements = await collect_n_measurements_concurrently(runnable, n);
  console.log("measurements", measurements);
  return get_median(measurements);
}

export async function average_n_measurements_concurrently(runnable, n) {
  const measurements = await collect_n_measurements_concurrently(runnable, n);
  console.log("measurements", measurements);
  return measurements.reduce((a, b) => a + b)/measurements.length;
}
