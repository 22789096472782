const DELIMITER = "|";


const data_dispatcher = {
  uuid: 0,
  subs: {},
  get_uuid() {
    this.uuid++;
    return this.uuid
  },
  subscribe_to(id, sub) {
    const uuid = this.get_uuid();
    const key = [id, uuid].join(DELIMITER);
    this.subs[key] = sub;
    return uuid;
  },
  unsubscribe_from(id, uuid) {
    const key = [id, uuid].join(DELIMITER);
    delete this.subs[key];
  },
  broadcast_to(channel, value) {
    for (const key in this.subs) {
      const [id, ] = key.split(DELIMITER);
      if (id === channel) {
        this.subs[key](value);
      }
    }
  }
};


export {data_dispatcher as DataDispatcher};
