import {EnvConfiguration} from "./env_configuration.js";

export const BufferbloatMeasurementMethod = {
  MEAN: "MEAN",
  MEDIAN: "MEDIAN",
};

let url;
try {
  url = process.env.PUBLIC_URL;
} catch (e) {}

export const AppConfiguration = {
  PING_INTERVAL: 100,
  LATENCY_MEASUREMENT_DURATION: 8,
  DOWNLOAD_DURATION: 20,
  UPLOAD_DURATION: 20,
  NUM_LATENCY_STREAMS: 6,
  BANDWIDTH_NUM_STREAMS: 12,
  NUM_STREAMS: {
    CF_SPEEDTEST: 6,
    CF_WORKERS: 6,
    FLYIO: 0
  },
  CHROME_ANDROID: {
    PING_INTERVAL_UNLOADED: 30,
    SKIP_EVERY: 8,
  },
  RESOURCE_TIMING_BUFFER_SIZE: 4e3,
  STATIC_RESOURCES_PATH: url,
  TEST_ID_QUERY: "test-id",
  IGNORE_N_LATENCIES: 3,
  DOWNLOAD_SAMPLE_WINDOW_SIZE: 100,
  DOWNLOAD_REFRESH_RATE_EVERY_N_SAMPLES : 50,
  IGNORE_FIRST_N_SECONDS: 4,
  MINIMUM_AVERAGE_WINDOW: 3,
  LATENCY_SOURCE_SELECTION_NUM_SAMPLES: 8,
  CLOUDFLARE_LATENCY_NUM_SAMPLES: 10,
  CLOUDFLARE_BAD_LATENCY_THRESHOLD: 1,
  WORKER_URL: EnvConfiguration.WORKER_URL,
  BUFFERBLOAT_MEASUREMENT_METHOD: BufferbloatMeasurementMethod.MEAN,
  RESULTS_SCHEMA_VERSION: "1.0.8",
};
