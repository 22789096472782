import Stage from "./stage";
import ManyChunksDownloadCollector from "../tools/many_chunks_download_collector";
import {DebugDataSource, RealtimeDataSource} from "../test_runner/data_source";
import BandwidthMeasurementDispatcher from "./bandwidth_measurement_dispatcher";
import {AppConfiguration} from "../app_configuration";


export default class DownlinkMeasurementStage extends Stage {
  async run() {
    super.run();
    const dispatcher = new BandwidthMeasurementDispatcher();
    await dispatcher.run(
      RealtimeDataSource.DOWNLINK_LATENCY,
      RealtimeDataSource.DOWNLINK_BYTES,
      RealtimeDataSource.DOWNLINK_BANDWIDTH,
      DebugDataSource.DOWNLINK_WARMUP,
      ManyChunksDownloadCollector,
      AppConfiguration.DOWNLOAD_DURATION
    );
  }
}