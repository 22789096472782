import React from 'react';
import "./tooltip_wrapper.scss";


export function TooltipWrapper(props) {
  const tooltip_text = props.tooltip_text;
  return <div className={"tooltip-wrapper"}>
    {props.children}
    {
      tooltip_text && (
        <span className="tooltip-text">{tooltip_text}</span>
      )
    }
  </div>
}
