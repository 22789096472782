import TimeUtility from "../utilities/time_utility";
import {AppConfiguration} from "../app_configuration";
import BlockSizeEstimator, {BASE_UPDATE_TIME, MULTIPLIER} from "./block_size_estimator.js";
import {average_n_measurements_concurrently} from "./latency_source_utilities.js";

export default class ManyChunksBandwidthCollector {
  constructor(measurement_cb) {
    this.measurement_cb = measurement_cb;
    const {CF_SPEEDTEST, CF_WORKERS, FLYIO} = AppConfiguration.NUM_STREAMS;
    this.num_streams = CF_SPEEDTEST + CF_WORKERS + FLYIO;
    this.controllers = Array(this.num_streams).fill(0).map(_ => new AbortController());
    this.block_size = null;
    this.ESTIMATION_TIMEOUT = null;
    this.speed_estimation_debug_channel = null;
    this.zero_time = 0;
    this.estimation_debug_info = {
      zero_time: this.zero_time,
      timeout: null,
      estimated_chunk_size: 2e6,
      duration: null,
      requests: []
    };
    this.duration_average = 0;
    this.duration_counter = 0;
  }

  async time_this_resources_transaction(url, async_runnable) {}

  async _drain_reader(reader) {
    let {done} = await reader.read();
    while (!done) {
      let result = await reader.read();
      done = result.done;
    }
  }

  async ensure_zero_time() {
    if (this.zero_time !== 0) {
      return
    }
    this.zero_time = await average_n_measurements_concurrently(async () => TimeUtility.time_this_runnable(
     async () => {
      await this.get_fetch_with_size(0, 0);
    }), 4);
    console.log("zero time", this.zero_time);
    this.estimation_debug_info.zero_time = this.zero_time;
  }

  update_block_size(duration, num_streams, each_update_time) {
    let [avg, n] = [this.duration_average, this.duration_counter];
    this.duration_counter += 1;
    this.duration_average = ((avg * n) + duration)/(n + 1);
    if (this.duration_counter < 10) {
      return;
    }

    let target = each_update_time * num_streams;

    if (each_update_time > BASE_UPDATE_TIME) {
      target *= ((MULTIPLIER - 1) / MULTIPLIER);
    }

    let multiplier = target / this.duration_average;
    multiplier = Math.min(3, multiplier);

    this.block_size *= multiplier;
    this.block_size = Math.min(this.block_size, 25e6);

    console.debug("block_size", parseFloat((this.block_size/1e6).toFixed(2)), "MB");
    this.duration_counter = 0;
    this.duration_average = 0;
  }

  async start_measuring() {
    await this.ensure_zero_time();
    console.debug("zero time", this.zero_time);
    const info = await BlockSizeEstimator.estimate_size_and_num_streams(
      (i, size) => this.get_fetch_with_size(size, i),
      this.zero_time
    );
    const {block_size, num_streams, update_time} = info;
    this.block_size = block_size;
    console.debug(
      "block size", parseFloat((this.block_size/1e6).toFixed(2)),
      "num streams", num_streams,
      "update time", update_time);
    this.cancel();
    let requests = [];
    for (let i = 0; i < num_streams; i++) {
      let p = new Promise(async resolve => {
        while (true) {
          let block_size = this.block_size;
          try {
            const {duration} = await TimeUtility.time_this_runnable(
              async () => {
                const res = await this.get_fetch(block_size, i);
                await this._drain_reader(res.body.getReader())
              }
            );
            this.measurement_cb(block_size);
            if (duration > this.zero_time) {
              this.update_block_size(duration - this.zero_time, num_streams, update_time);
            }
          } catch (e) {
            if (! (e instanceof DOMException)) {
              console.error("error", e);
            }
            resolve();
            break;
          }
        }
      });
      requests.push(p);
      await TimeUtility.sleep(update_time);
    }
    await Promise.all(requests);
  }

  get_fetch(size, i) {
    return this.get_fetch_with_size(size, i)
  }

  get_method_and_url_and_body(size, i) {}

  get_fetch_with_size(size, i) {
    const [method, url, body] = this.get_method_and_url_and_body(size, i);
    const signal = this.controllers[i].signal;
    return fetch(url, {
      method: method,
      body: body,
      ...{signal}
    });
  }

  cancel() {
    this.controllers.forEach(c => c.abort());
    this.controllers = Array(this.num_streams).fill(0).map(_ => new AbortController());
  }
}
