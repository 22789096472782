export const AppEvent = {
  FINISHED_STAGE: "finished_stage",
  RESTARTING_TEST: "restarting_test",
  ERROR: "error",
};

export const CalculatedDataSource = {
  REPORT: "report",
  SAVED_TEST_UUID: "saved_test_uuid"
};

export const UIEvents = {
  SCROLL: "scroll"
};

export const DebugDataSource = {
  DOWNLINK_SPEED_ESTIMATION: "downlink_estimation",
  DOWNLINK_WARMUP: "downlink_warmup",
  UPLINK_SPEED_ESTIMATION: "uplink_estimation",
  UPLINK_WARMUP: "uplink_warmup",
  LATENCY_MEASUREMENT_SOURCE: "latency_measurement_source"
};

export const RealtimeDataSource = {
  UNLOADED_LATENCY: "unloaded_latency",
  DOWNLINK_LATENCY: "downlink_latency",
  DOWNLINK_BANDWIDTH: "downlink_bandwidth",
  UPLINK_LATENCY: "uplink_latency",
  UPLINK_BANDWIDTH: "uplink_bandwidth",
  AGGREGATED_TEST_DATA: "aggregated_test_data",
  DOWNLINK_BYTES: "downlink_bytes",
  UPLINK_BYTES: "uplink_bytes",
};

export const LoadedDataSource = {
  BOX_PLOT_SCALE: "box_plot_scale",
  LOADED_UNLOADED_LATENCIES: "loaded_unloaded_latencies",
  LOADED_DOWNLINK_LATENCIES: "loaded_downlink_latencies",
  LOADED_UPLINK_LATENCIES: "loaded_uplink_latencies",
  LOADED_DOWNLINK_SPEED: "loaded_downlink_speed",
  LOADED_UPLINK_SPEED: "loaded_uplink_speed",
};
