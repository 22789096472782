import ManyChunksBandwidthCollector from "./many_chunks_bandwidth_collector";
import {DebugDataSource} from "../test_runner/data_source";
import TimeUtility from "../utilities/time_utility";
import URLBuilder from "./url_builder.js";


export default class ManyChunksDownloadCollector extends ManyChunksBandwidthCollector {
  speed_estimation_debug_channel = DebugDataSource.DOWNLINK_SPEED_ESTIMATION;
  ESTIMATION_TIMEOUT = 2e3;
  async time_this_resources_transaction(url, async_runnable) {
    return await TimeUtility.time_this_resources_download(url, async_runnable);
  }

  get_method_and_url_and_body(size, i) {
    return ["GET", URLBuilder.get_download_url(size, i), null]
  }
}

