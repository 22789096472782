import React, {Component} from "react";
import {Button} from "react-bootstrap";
import '../components/component_styles.scss';
import {AppConfiguration} from "../app_configuration";
import ShareResultsComponent from "../components/share_results_component";
import "./bufferbloat_test_component.scss"
import TestDetailViewComponent from "./test_detail_view_component";
import {InitialContext, TestContext} from "../stages/context";
import StatisticsView from "./statistics_view";
import {Stages} from "../stages/stages";
import BufferbloatTestRunner from "../test_runner/bufferbloat_test_runner";
import LeftColumnComponent from "./left_column_component";
import ReportGenerator from "../report/report_generator";
import TestDataCollector from "../report/test_data_collector";
import TestResultPublisher from "../report/test_result_publisher";
import ModelLoader from "../model/model_loader";
import TestResultsManager from "../tools/test_results_manager";
import TestWalkthroughComponent from "./test_walkthrough_component";
import TestTitle from "./test_title";
import {DataDispatcher} from "../test_runner/data_dispatcher";
import {AppEvent, CalculatedDataSource} from "../test_runner/data_source";
import URLBuilder from "../tools/url_builder.js";

export default class BufferBloatTestComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: InitialContext
    };
    performance.setResourceTimingBufferSize(AppConfiguration.RESOURCE_TIMING_BUFFER_SIZE);
    this.runner = null;
    this.report_generator = null;
    this.test_data_collector = null;
    this.test_result_publisher = null;
  }

  start_services() {
    this.report_generator = new ReportGenerator();
    this.test_data_collector = new TestDataCollector();
    this.test_result_publisher = new TestResultPublisher();
    this.report_generator.start();
    this.test_data_collector.start();
    this.test_result_publisher.start();
  }

  stage_updated([new_stage, idx]) {
    const context = {
      current_stage: new_stage,
      current_stage_idx: idx,
      current_test_version: AppConfiguration.RESULTS_SCHEMA_VERSION
    };
    this.setState({
      context
    })
  }

  componentDidMount() {
    //this.debug().then();
    this.load_test_results_if_test_id_provided().then();
  }

  reset_url_params() {
    const path = window.location.pathname.split("/")
      .filter(s => s !== "test-results")
      .join("/");
    window.location.replace(`${window.location.origin}${path}`);
  }

  async load_test_results_if_test_id_provided() {
    const test_id = this.props.test_id;
    if (!test_id) {
      return;
    }
    const is_version_valid = (test_version) => {
      if (!test_version || typeof test_version != "string") {
        return false;
      }
      const [test_maj_v,,] = test_version.split(".").map(d => parseInt(d));
      const [current_maj_v,,] = AppConfiguration.RESULTS_SCHEMA_VERSION.split(".").map(d => parseInt(d));
      return test_maj_v >= current_maj_v;
    };
    let results = "";
    try {
      results = await TestResultsManager.get_test_results(test_id);
    } catch (e) {
      this.reset_url_params();
      return ;
    }
    if (!is_version_valid(results.version)) {
      this.reset_url_params();
      return;
    }
    await this.setState({
      context: {
        current_stage: Stages.DONE,
        current_stage_idx: Object.keys(Stages).length - 1,
        current_test_version: results.version
      }
    });
    new ModelLoader().load_model(results);
    DataDispatcher.broadcast_to(CalculatedDataSource.SAVED_TEST_UUID, test_id);
  }

  async debug() {
    AppConfiguration.LATENCY_MEASUREMENT_DURATION = 1;
    //ManyChunksBandwidthCollector.prototype.estimate_size = async () => 25e6;
    return;
    //LatencySourceSelector.select_latency_source = () => LatencySource.GOOGLE_FONTS_TTFB;
    AppConfiguration.PING_INTERVAL = 500;
    //AppConfiguration.DOWNLOAD_DURATION = 2;
    //AppConfiguration.UPLOAD_DURATION = 1;
    //AppConfiguration.IGNORE_FIRST_N_SECONDS = 1;
    URLBuilder.get_download_url = (size, i) => {
      console.log("i is", i);
      i = i || 0;
      return `http://localhost:808${i}/down?bytes=${size}`;
      //return `http://localhost:8080/down?bytes=${size}`;
    };
    URLBuilder.get_upload_url = (i) => {
      console.log("i is", i);
      i = i || 0;
      return `http://localhost:808${i}/up`;
    };
    //await this.start_test();
    return ;
    AppConfiguration.PING_INTERVAL = 300;
    await this.setState({
      context: {
        current_stage: Stages.DOWNLINK,
        current_stage_idx: 2,
        //current_test_version: AppConfiguration.RESULTS_SCHEMA_VERSION
      }
    });
  }

  async start_test() {
    this.start_services();
    console.log("replacing history state");
    window.history.replaceState("", "",
      window.location.pathname.split("/").pop() || "."
    );
    this.runner = new BufferbloatTestRunner(this.stage_updated.bind(this));
    await this.runner.run();
  }

  async test_again() {
    console.log("testing again!");
    DataDispatcher.broadcast_to(AppEvent.RESTARTING_TEST, true);
    await this.setState({
      context: InitialContext
    });
    this.start_test();
  }

  render() {
    let test_done =
        (this.state.context.current_stage_idx >= Object.keys(Stages).length - 1) ?
        "test-done": "";

    return (
      <TestContext.Provider value={this.state.context}>
        <div className={`container bufferbloat-test-component ${test_done}`}>
          <div className="title">
            <TestTitle />
          </div>
          <div className="frame-container">
            <div className="top-strip">
            </div>
            <div className="body">
              <div className="flex-container top-container">
                <LeftColumnComponent />
                <TestDetailViewComponent/>
              </div>
              {
                this.state.context.current_stage === Stages.IDLE &&
                (
                  <div className="start-test-container">
                    <Button bsSize="large" className="secondary" onClick={this.start_test.bind(this)}>Start Test</Button>
                  </div>
                )
              }
              <StatisticsView />
            </div>
            <div className="bottom-strip">
              <ShareResultsComponent
                test_again={this.test_again.bind(this)}
              />
            </div>
          </div>
          <TestWalkthroughComponent className={"small-screen"}/>
        </div>
      </TestContext.Provider>
    );
  }
}


