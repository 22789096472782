import {AppConfiguration} from "../app_configuration";
import LatencyCollector from "./latency_collector.js";

export default class LatencyCollectorProvider {
  static provide(data_cb, done_cb, interval=AppConfiguration.PING_INTERVAL) {
    return new LatencyCollector(
      interval,
      data_cb,
      done_cb
    );
  }
}
