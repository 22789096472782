import React, {Component} from 'react';
import {TestContext} from "../stages/context";
import {Stages} from "../stages/stages";
import BufferbloatGradeComponent from "./bufferbloat_grade_component";
import ConnectionResultShieldBanner from "./connection_result_shield_banner.js";
import "./test_report_component.scss"
import YourConnectionBanner from "./your_connection_banner.js";

export default class TestReportComponent extends Component {
  render() {
    if (this.context.current_stage !== Stages.DONE) {
      return null;
    }
    return (
      <div className="test-report-component">
        <BufferbloatGradeComponent />
        <YourConnectionBanner/>
      </div>
    )
  }
}

TestReportComponent.contextType = TestContext;
