import {AppConfiguration} from "../app_configuration.js";
import React, {Component} from 'react';
import {TestContext} from "../stages/context.js";
import {FAILURE_REASON} from "../report/report_generator.js";
import {TooltipWrapper} from "./tooltip_wrapper.js";
import {check_passed} from "../report/report_processing.js";

export default class YourConnectionBannerTableRow extends Component {
  render() {
    const category = this.props.category,
      impact = this.props.impact,
      version = this.context.current_test_version;
    const img = (name, tooltip_text) => (
      <TooltipWrapper
        tooltip_text={tooltip_text}
      >
        <img src={AppConfiguration.STATIC_RESOURCES_PATH + "/images/" + name} alt={name}/>
      </TooltipWrapper>
    );
    const good = (<td className={"indicator"}>
      {img("impact_checkmark.svg")}
    </td>);
    const bad = (tooltip_text) => (<td className={"indicator"}>
      {img("impact_warning.svg", tooltip_text)}
    </td>);
    const text_bad = `Your internet connection may be too slow for reliable ${category.toLowerCase()}.`;
    const text_bad_with_bufferbloat = `While your Internet connection may already be 
      too slow for ${category.toLowerCase()}, the issue may be made 
      even worse by bufferbloat.`;
    const text_bad_only_bufferbloat = `Your internet connection is fast enough to support 
      ${category.toLowerCase()}, but you may experience intermittent issues due 
      to bufferbloat.`;
    const [passed, failed_with_bufferbloat] = check_passed(impact, version);
    if (passed) {
      return (
        <>
          {good}{good}
        </>
      )
    } else if (failed_with_bufferbloat) {
      return (
        <>
          {good}{bad(text_bad_only_bufferbloat)}
        </>
      )
    }
    return (
      <>
        {bad(text_bad)}{bad(text_bad_with_bufferbloat)}
      </>
    )
  }
}

YourConnectionBannerTableRow.contextType = TestContext;
