import Stage from "./stage";
import LatencyCollectorProvider from "../tools/latency_collector_provider";
import {AppConfiguration} from "../app_configuration";
import {RealtimeDataSource} from "../test_runner/data_source";
import {DataDispatcher} from "../test_runner/data_dispatcher";
import {RuntimeEnvironment} from "../utilities/runtime_environment.js";

export default class LatencyMeasurementStage extends Stage {
  async run() {
    const name = "LatencyMeasurementStage";
    super.run();
    const dispatch_normally = (latency) => {
      DataDispatcher.broadcast_to(
        RealtimeDataSource.UNLOADED_LATENCY,
        latency
      );
    };

    let counter = 0;
    const dispatch_latency_android = (latency) => {
      if (counter >= AppConfiguration.CHROME_ANDROID.SKIP_EVERY) {
        dispatch_normally(latency);
        counter = 0;
      }
      counter += 1;
    };
    const dispatch = RuntimeEnvironment.is_android ? dispatch_latency_android : dispatch_normally;
    const interval = RuntimeEnvironment.is_android ?
      AppConfiguration.CHROME_ANDROID.PING_INTERVAL_UNLOADED :
      AppConfiguration.PING_INTERVAL;
    console.debug(name, "latency measurement started");
    let first_latency_received = false;
    const collector = LatencyCollectorProvider
      .provide(
        (latency) => {
          if (!first_latency_received) {
            first_latency_received = true;
            console.debug(name, "first value was received, starting the timer now");
            setTimeout(() => {
              collector.cancel();
            }, AppConfiguration.LATENCY_MEASUREMENT_DURATION * 1e3);
          }
          dispatch(latency);
        },
        null,
        interval
      );
    await collector.start();
    console.debug(name, "latency measurement cancelled");
  }
}
