import Pipe from "./pipe";


export default class TimeFilterPipe extends Pipe {
  constructor(allow_after_ms) {
    super();
    this.allow_after_ms = allow_after_ms;
    this.allow = false;
    this.started = false;
  }

  process(new_value, data) {
    if (!this.started) {
      this.started = true;
      console.debug("time filter started");
      setTimeout(() => {
        this.allow = true;
        console.debug("time filter ended");
      }, this.allow_after_ms);
    }
    if (!this.allow) {
      return;
    }
    this.out(new_value);
  }
}
