import React from 'react';
import {Stages} from "./stages";
import {AppConfiguration} from "../app_configuration.js";


export const InitialContext = {
  current_stage: Stages.IDLE,
  current_stage_idx: 0,
  current_test_version: AppConfiguration.RESULTS_SCHEMA_VERSION
};

export const TestContext = React.createContext(InitialContext);

let AppContextObject = {
  latency_source: null,
  loaded_test: null
};

export function reset_app_context() {
  AppContextObject = {
    ...AppContextObject,
    loaded_test: null
  };
}

export {AppContextObject};
