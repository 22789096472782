export default class LazyBuffer {
  constructor(skip_every=10, debounce_ms=200) {
    this.subs = [];
    this.debounce_ms = debounce_ms;
    this.skip_every = skip_every;
    this.counter = 0;
    this.buffer = [];
    this.timer = null;
    this.value = null;
  }

  reducer(values) {}

  register_subscriber(sub){
    this.subs.push(sub)
  }

  _broadcast() {
    const value = this.reducer(this.buffer);
    this.value = value;
    for (const sub of this.subs) {
      sub(value);
    }
  }

  update_chunk(values) {
    this.buffer = this.buffer.concat(values);
    this._broadcast();
  }

  update(value) {
    this.buffer.push(value);
    clearTimeout(this.timer);
    this.counter += 1;
    if (this.counter > this.skip_every) {
      this.counter = 0;
      this._broadcast();
    }
    this.timer = setTimeout(this._broadcast.bind(this), this.debounce_ms);
  }
}

