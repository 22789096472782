import React, {Component} from 'react';
import "./connection_result_shield_banner.scss";
import "./your_connection_banner.scss";
import {CalculatedDataSource, UIEvents} from "../test_runner/data_source";
import {DataDispatcher} from "../test_runner/data_dispatcher.js";
import YourConnectionBannerTableRow from "./your_connection_banner_table_row.js";


export default class YourConnectionBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      real_world_impact: {}
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    let id = DataDispatcher.subscribe_to(CalculatedDataSource.REPORT, (report) => {
      this.setState({
        real_world_impact: report.real_world_impact
      });
      console.debug("impact", report.real_world_impact);
    });
    this.subscriptions.push([CalculatedDataSource.REPORT, id])
  }

  componentWillUnmount() {
    for (let [channel, id] of this.subscriptions) {
      DataDispatcher.unsubscribe_from(channel, id);
    }
  }


  render() {

    return (
      <div className="your-connection-is-good-for real-world-impact-banner">
        <div className="header">
          <h4>YOUR CONNECTION</h4>
          <table>
            <tr className={"header-row"}>
              <th>
              </th>
              <th className={"header"}>
                Under Ideal Conditions
              </th>
              <th className={"header"}>
                Currently, Due To Bufferbloat
              </th>
            </tr>
            {
              Object.keys(this.state.real_world_impact)
                .map(category => [category, this.state.real_world_impact[category]])
                .map(([category, impact]) => {
                  return (
                    <tr className={"body-row"}>
                      <td>{category}</td>
                      <YourConnectionBannerTableRow
                        category={category}
                        impact={impact}
                      />
                    </tr>
                  );
              })
            }
          </table>
          <div className="read-more">
            <a href="#question-7">
              Read More
            </a>
          </div>
        </div>
      </div>
    )
  }
}
