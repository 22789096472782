import React, {Component} from 'react';
import * as d3 from 'd3';
import StatisticsUtilities from "../utilities/statistics_utilities";
import ScreenUtilities from "../utilities/screen_utilities.js";
import "./box_plot.scss"


export default class BoxPlot extends Component {
  TRANSITION_TIME = 300;
  DOT_COLOUR = "#4a90e2";

  constructor(props) {
    super(props);
    this.chart_component = React.createRef();
    this.data = props.data.filter(d => !this.is_invalid(d)) || [];
    this.scale = props.scale;
    // eslint-disable-next-line no-template-curly-in-string
    this.tooltip_html = this.props.tooltip_html || 'value: ${d.value}';
    this.resize_timer = null;
    this.listening = null;
    this._tooltip = null;
  }

  calculate_important_values(data) {
    let sorted = [...data.map(d => d.value)].sort(d3.ascending);
    return StatisticsUtilities.calculate_important_values(sorted);
  }

  listen_to_resize() {
    if (this.listening) {
      console.log("already listening");
      return;
    }
    const WINDOW_RESIZE_DEBOUNCE = 200;
    this.listening = () => {
      if (this.resize_timer) {
        clearTimeout(this.resize_timer);
      }
      this.resize_timer = setTimeout(() => {
        this.redraw();
        this.resize_timer = null;
      }, WINDOW_RESIZE_DEBOUNCE);
    };
    window.addEventListener('resize', this.listening);
  }

  get_size_based_on_window_size() {
    const BOOTSTRAP_SM = 768;
    const BOOTSTRAP_MD = 992;
    const BOOTSTRAP_LG = 1200;
    let window_size = ScreenUtilities.get_screen_width();
    if (window_size < BOOTSTRAP_SM) {
      return [window_size * 9/12, 70];
    }
    if (window_size < BOOTSTRAP_MD) {
      return [window_size * 5/12, 70];
    }
    if (window_size < BOOTSTRAP_LG) {
      return [window_size * 6/12, 70];
    }
    return [630, 70];
  }

  update_size() {
    const [width, height] = this.get_size_based_on_window_size();
    this.width = width;
    this.height = height;
  }

  get_chart_parameters() {
    const margin = {top: 10, right: 10, bottom: 10, left: 10},
      width = this.width - margin.left - margin.right,
      height = this.height - margin.top - margin.bottom,
      point_position_relative_to_top = 3/4 * height,
      middle_anchor_relative_to_top = 1/4 * height;
    return [margin, width, height, point_position_relative_to_top, middle_anchor_relative_to_top];
  }

  draw() {
    this.update_size();
    let [margin, width, height] = this.get_chart_parameters();

    let svg = d3.select(this.chart_component.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    this._svg = svg;
    this._top_level_group = svg.append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");
    let data = this.data;
    this.update_scale(data);

    this._points_container = this._top_level_group.append("g")
      .attr("id", "data_points");

    this.add_tooltip();
    this.draw_points(data);
    this.add_line_with_ticks(data);
    this.add_quartile_box_if_it_doesnt_exist(data);
  }

  componentDidMount() {
    this.listen_to_resize();
    this.draw();
  }

  add_tooltip() {
    if (this._tooltip) {
      return;
    }
    this._tooltip = d3.select(this.chart_component.current)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("border", "solid")
      .style("background-color", "white")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px");
  }

  set_circle_attributes(selection) {
    let [,,, rel_pos] = this.get_chart_parameters();
    let tooltip = this._tooltip;
    let component = this;
    const CURSOR_OFFSET = 30;

    let mouseover = function(d) {
      tooltip.style("opacity", 1);
      d3.select(this)
        .style("stroke", "black")
        .style("opacity", 1);
    };

    let mousemove = function(e, d) {
      let relative_dist = d3.pointer(e, this);
      tooltip.html(eval("`" + component.tooltip_html + "`"))
        .style("left", (relative_dist[0]) + "px")
        .style("top", ((relative_dist[1]) + CURSOR_OFFSET) + "px");
    };

    let mouseleave = function(d) {
      tooltip
        .style("opacity", 0);
      d3.select(this)
        .style("stroke", "none")
        .style("opacity", 0.8);
    };

    selection.attr("cy", () => rel_pos)
      .attr("r", 3)
      .attr("fill", this.DOT_COLOUR)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave);
  }

  draw_points(data) {
    let x = this._x_scale;
    let selection = this._points_container
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.value));
    this.set_circle_attributes(selection);
  }

  get_indicator_height() {
    if (this.width < 300) {
      return this.width/15;
    }
    if (this.width < 500) {
      return this.width/30;
    }
    return 20;
  }

  update_points_locations(data) {
    let circles = this._points_container
      .selectAll("circle")
      .data(data);

    circles
      .transition()
      .duration(this.TRANSITION_TIME)
      .attr("cx", d => this._x_scale(d.value));
  }

  add_line_with_ticks(data) {
    let indicator_tick_height = this.get_indicator_height()/2,
      indicator_stroke_colour = '#979797',
      x = this._x_scale,
      svg = this._top_level_group;

    let {min, max} = this.calculate_important_values(data);
    let [, width,,, mid_anchor_rel] = this.get_chart_parameters();

    let middle_anchor = svg.append("g")
      .attr("transform", "translate(0," + mid_anchor_rel + ")");

    this._middle_anchor = middle_anchor;

    let x_min = x(min) || 0,
        x_max = x(max) || width;

    this.main_line = middle_anchor.append("line")
      .attr("x1", x_min)
      .attr("y1", 0)
      .attr("x2", x_max)
      .attr("y2", 0)
      .attr("stroke", indicator_stroke_colour);

    function make_tic(at) {
      return middle_anchor.append("line")
        .attr("x1", at)
        .attr("y1", -indicator_tick_height/2)
        .attr("x2", at)
        .attr("y2", +indicator_tick_height/2)
        .attr("stroke", indicator_stroke_colour);
    }

    this.tick_1 = make_tic(x_min);
    this.tick_2 = make_tic(x_max);
  }

  add_quartile_box_if_it_doesnt_exist(data) {
    if (this._quartile_box || data.length < 5) {
      return
    }
    this.add_quartile_box(data);
  }

  add_quartile_box(data) {
    let indicator_box_height = this.get_indicator_height(),
      indicator_stroke_colour = '#979797',
      x = this._x_scale;
    let {q1, q3, median} = this.calculate_important_values(data);

    this._quartile_box =  this._middle_anchor
      .append("g")
      .attr("id", "quartile_box");

    this._quartile_rect = this._quartile_box.append("rect")
      .attr("x", x(q1))
      .attr("y", -indicator_box_height/2)
      .attr("height", indicator_box_height)
      .attr("width", x(q3) - x(q1))
      .attr("stroke-width", 1.5)
      .attr("fill", "white")
      .attr("stroke", indicator_stroke_colour);

    this._median_line = this._quartile_box.append("line")
      .attr("x1", x(median))
      .attr("x2", x(median))
      .attr("y1", -indicator_box_height/2)
      .attr("y2", +indicator_box_height/2)
      .attr("stroke", indicator_stroke_colour)
      .attr("stroke-width", 1.5);
  }

  update_scale(data) {
    let [, width,] = this.get_chart_parameters();
    if (this.props.scale) {
      let [min, max] = this.props.scale;
      this._x_scale = d3.scaleLinear()
        .domain([max, min])
        .range([width, 0]);
      return;
    }
    let {min, max} = this.calculate_important_values(data);
    this._x_scale = d3.scaleLinear()
      .domain([max, min])
      .range([width, 0]);
  }

  update_line_with_ticks(data) {
    let {min, max} = this.calculate_important_values(data),
      x = this._x_scale,
      x_min = x(min),
      x_max = x(max);
    const transition_tick = (tick, x) => {
      tick.transition()
        .duration(this.TRANSITION_TIME)
        .attr("x1", x)
        .attr("x2", x);
    };
    this.main_line.transition()
      .duration(this.TRANSITION_TIME)
      .attr("x1", x_min)
      .attr("x2", x_max);
    transition_tick(this.tick_1, x_min);
    transition_tick(this.tick_2, x_max);
  }

  update_box(data) {
    if (!this._quartile_box) {
      return;
    }
    let {q1, q3, median} = this.calculate_important_values(data);
    let x = this._x_scale;
    this._quartile_rect
      .transition()
      .duration(this.TRANSITION_TIME)
      .attr("x", x(q1))
      .attr("width", x(q3) - x(q1));
    this._median_line
      .transition()
      .duration(this.TRANSITION_TIME)
      .attr("x1", x(median))
      .attr("x2", x(median))
  }

  refresh_chart() {
    let data = this.data;
    this.update_scale(data);
    this.update_points_locations(data);
    this.draw_points(data);
    this.add_quartile_box_if_it_doesnt_exist(data);
    this.update_line_with_ticks(data);
    this.update_box(data);
  }

  add_point(datum) {
    this.data.push(datum);
    this.refresh_chart();
  }

  is_invalid(datum) {
    return !datum;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.data.length === prevProps.data.length) {
      this.refresh_chart();
      return;
    }
    let datum = prevProps.data.slice(-1).pop();
    if (this.is_invalid(datum)) {
      return;
    }
    this.data.push(datum);
    this.refresh_chart();
  }

  redraw() {
    this._svg.remove();
    this._quartile_box = null;
    this.draw();
  }

  render() {
    return (
      <>
        <div className={"box-plot"} ref={this.chart_component}/>
        {
          /*
        <button onClick={this.redraw.bind(this)}>test</button>
          * */
        }
      </>
    );
  }
}
