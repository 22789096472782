import {main_candidate} from "./latency_candidates.js";
import {AppConfiguration} from "../app_configuration.js";

let id = 0;
export function request_uuid() {
  id += 1;
  return id;
}

export const BandwidthSources = {
  CLOUDFLARES_SPEEDTEST: "cloudflares_speedtest",
  CLOUDFLARE_WORKERS: "cloudflare_workers",
  FLYIO: "flyio"
};

const CLOUDFLARE_HOST = "https://speed.cloudflare.com";
const CLOUDFLARE_WORKER_HOST = (i) => `https://ping-cf-${i}.waveform.com`;
const FLYIO_HOST = (i) => `https://waveform-sjc-speed-${i}.fly.dev`;

const download_source_lookup = {
  [BandwidthSources.CLOUDFLARES_SPEEDTEST]: (i, bytes) => `${CLOUDFLARE_HOST}/__down?bytes=${bytes}&uuid=${request_uuid()}`,
  [BandwidthSources.CLOUDFLARE_WORKERS]: (i, bytes) => `${CLOUDFLARE_WORKER_HOST(i)}/down?bytes=${bytes}&uuid=${request_uuid()}`,
  [BandwidthSources.FLYIO]: (i, bytes) => `${FLYIO_HOST(i)}/down?bytes=${bytes}`,
};

const upload_source_lookup = {
  [BandwidthSources.CLOUDFLARES_SPEEDTEST]: (i) => `${CLOUDFLARE_HOST}/__up`,
  [BandwidthSources.CLOUDFLARE_WORKERS]: (i) => `${CLOUDFLARE_WORKER_HOST(i)}/up`,
  [BandwidthSources.FLYIO]: (i) => `${FLYIO_HOST(i)}/up`,
};

export default class URLBuilder {
  static get_source(i) {
    const {CF_SPEEDTEST, CF_WORKERS, FLYIO} = AppConfiguration.NUM_STREAMS;
    if (i < CF_SPEEDTEST) {
      return {index: i, source: BandwidthSources.CLOUDFLARES_SPEEDTEST};
    }
    i -= CF_SPEEDTEST;
    if (i < CF_WORKERS) {
      return {index: i, source: BandwidthSources.CLOUDFLARE_WORKERS};
    }
    i -= CF_WORKERS;
    if (i <= FLYIO) {
      return {index: i, source: BandwidthSources.CLOUDFLARES_SPEEDTEST};
    }
    console.error("Invalid stream index, returning cf speedtest instead", i);
    return {index: i, source: BandwidthSources.CLOUDFLARES_SPEEDTEST};
  }
  static get_cf_worker_download_url(size, i) {
    return download_source_lookup[BandwidthSources.CLOUDFLARE_WORKERS](i, size);
  }

  static  get_download_url(size=0, i) {
    const {index, source} = this.get_source(i);
    return download_source_lookup[source](index, size);
  }

  static  get_upload_url(i) {
    const {index, source} = this.get_source(i);
    return upload_source_lookup[source](index);
  }

  static get_fastly_url(i) {
    return `https://waveform-speedtest-${i}.global.ssl.fastly.net/`;
  }

  static get_cf_workers_url(i) {
    return `https://ping-cf-${i}.waveform.com/down?bytes=0`;
  }

  static get_cf_cached_url(i) {
    return `https://speedtest-c${i}.waveform.com/down?bytes=0`;
  }

  static get_cloudflare_ping_url() {
    const source = download_source_lookup[BandwidthSources.CLOUDFLARES_SPEEDTEST];
    return source(0, 0);
  }

  static get_gfonts_url() {
    return `${main_candidate}#id=${request_uuid()}`;
  }

  static get_my_ip_url() {
    return `${AppConfiguration.WORKER_URL}/my-ip`;
  }
}
