import LatencyMeasurementStage from "../stages/latency_measurement_stage";
import DownlinkMeasurementStage from "../stages/downlink_measurement_stage";
import UplinkMeasurementStage from "../stages/uplink_measurement_stage";
import {Stages} from "../stages/stages";
import {DataDispatcher} from "./data_dispatcher";
import {AppEvent} from "./data_source";


export default class BufferbloatTestRunner {
  constructor(update_state_cb) {
    this.uuid = 0;
    this.stage_idx = 0;
    this.stages = {
      [Stages.UNLOADED]: new LatencyMeasurementStage(),
      [Stages.DOWNLINK]: new DownlinkMeasurementStage(),
      [Stages.UPLINK]: new UplinkMeasurementStage(),
    };
    this.update_cb = update_state_cb;
    performance.clearResourceTimings(); // Resources must be cleared every now n then.
  }

  async run() {
    const increment_and_update = (stage) => {
      this.stage_idx += 1;
      this.update_cb([stage, this.stage_idx]);
    };
    for (const stage in this.stages) {
      increment_and_update(stage);
      console.debug("STARTED STAGE", stage);
      await this.stages[stage].run();
      console.debug("FINISHED STAGE", stage);
      DataDispatcher.broadcast_to(AppEvent.FINISHED_STAGE, stage);
    }
    increment_and_update(Stages.DONE);
    DataDispatcher.broadcast_to(AppEvent.FINISHED_STAGE, Stages.DONE);
  }
}
