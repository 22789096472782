import {FAILURE_REASON} from "./report_generator.js";
import StatisticsUtilities from "../utilities/statistics_utilities.js";
import * as d3 from 'd3';
import {AppConfiguration} from "../app_configuration.js";
import {is_median} from "../version_specific/report_generation.js";

const CATEGORY_STATUS = {
  GOOD: "GOOD",
  BAD: "BAD",
  BAD_WITH_BUFFERBLOAT: "BAD_WITH_BUFFERBLOAT"
};

export const OLD_TABLE_VERSION = "1.0.2";
export const NEW_TABLE_VERSION = "1.0.3";

export function get_important_statistics_as_dict(latencies) {
  const statistics = latencies.map(l => [...l].sort(d3.ascending))
      .map(l => StatisticsUtilities.calculate_important_values(l)),
    medians = statistics.map(s => s.median),
    means = statistics.map(s => s.mean),
    values = (is_median() ? medians : means),
    latency_unloaded  = values[0],
    max_latency = Math.max(...values),
    latency_95th = statistics.map(s => s.quantile_95th),
    latency_95th_unloaded = latency_95th[0],
    max_latency_95th = Math.max(...latency_95th),
    jitters = statistics.map(s => s.jitter),
    jitter_unloaded = jitters[0],
    max_jitter = Math.max(...jitters);

  return {
    medians: medians,
    means: statistics.map(s => s.mean),
    means_95th: statistics.map(s => s.mean_95th),
    latency_unloaded: latency_unloaded,
    max_latency: max_latency,
    latency_95th: latency_95th,
    latency_95th_unloaded: latency_95th_unloaded,
    max_latency_95th: max_latency_95th,
    jitter_unloaded: jitter_unloaded,
    max_jitter: max_jitter
  }
}

export function check_passed(impact, version) {
  let passed, failed_with_bufferbloat;
  if (version > OLD_TABLE_VERSION) {
    passed = impact.passed;
    failed_with_bufferbloat = !Object.keys(impact.where_it_failed)
      .map(k => impact.where_it_failed[k])
      .filter(s => s === FAILURE_REASON.FAILED_NORMALLY)
      .length > 0;
  } else {
    passed = (impact.status === CATEGORY_STATUS.GOOD);
    failed_with_bufferbloat = !impact.where_it_failed
      .filter(s => s[1] === CATEGORY_STATUS.BAD)
      .length > 0;
  }
  return [passed, failed_with_bufferbloat];
}

