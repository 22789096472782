import {AppConfiguration} from "../app_configuration.js";
import TimeUtility from "../utilities/time_utility.js";

export const BASE_SIZE = 1e4;
export const ALPHA = 1.8;
export const BASE_UPDATE_TIME = 300;
export const MAX_BLOCK_SIZE = 25e6;
export const MULTIPLIER = 3;
const HIGH_SPEED_BLOCK_SIZE = 15e6;

export default class BlockSizeEstimator {
  static async time_this_block(block_size, n, provider) {
    const async_runnable = async () => {
      let requests = [...Array(n).keys()].map(i => {
        return provider(i, block_size).then(res => res.text());
      });
      await Promise.all(requests);
    };
    return TimeUtility.time_this_runnable(async_runnable);
  }

  static get_target(zero_time, num_streams, update_time) {
    if (num_streams * BASE_UPDATE_TIME < zero_time) {
      update_time = MULTIPLIER * zero_time / num_streams;
      return {target: ((MULTIPLIER - 1) * zero_time), update_time: update_time};
    }
    return {target: num_streams * update_time, update_time: update_time};
  }

  static async estimate_size_and_num_streams(provider, zero_time) {
    let test_size = BASE_SIZE / ALPHA;
    const {CF_SPEEDTEST: speedtest, CF_WORKERS: workers} = AppConfiguration.NUM_STREAMS;
    let streams = speedtest;
    const max_streams = speedtest + workers;
    const high_speed_test_size = HIGH_SPEED_BLOCK_SIZE * speedtest;
    const max_size = MAX_BLOCK_SIZE * max_streams;
    let {target, update_time} = BlockSizeEstimator.get_target(zero_time, streams, BASE_UPDATE_TIME);
    let duration = target/ALPHA; // for leap to be ALPHA the first time
    do {
      let leap = target/duration;
      test_size *= leap;
      if ((test_size * streams) > max_size && streams === max_streams) {
        return {block_size: MAX_BLOCK_SIZE, num_streams: streams, update_time: update_time};
      }
      if ((test_size * streams) > high_speed_test_size) {
        test_size *= (streams/max_streams);
        streams = max_streams;
        let info = BlockSizeEstimator.get_target(zero_time, streams, update_time);
        target = info.target;
        update_time = info.update_time;
      }
      if (leap < ALPHA) {
        return {block_size: test_size, num_streams: streams, update_time: update_time};
      }
      const info = await BlockSizeEstimator.time_this_block(test_size, streams, provider);
      duration = info.duration - zero_time;
      duration = (duration > 0) ? duration : info.duration;
    } while (duration < target);
    return {
      // in case we overshot a lot
      block_size: test_size * (target / duration),
      num_streams: streams,
      update_time: update_time
    };
  }
}
